import React from 'react';
import "./index.css";




const Subheader = () => {


    return(


       <>
        
        <div className="subheader-container">
            <h2 className='subheader-title'>Code 💻, Learn 🧠, and Grow 📈</h2>
        </div>






    

</>
        
  
      
    )
}

export default Subheader;
import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter22 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter21">⬅️ Chapter 21</a></p>
  
</div>
              <h1 className="main-title">Chapter 22: Heroku Deployment</h1>
            </div>
        </div>
      </div>


      <p>The last chapter will focus on deploying the Django web app on Heroku, so that other people can use it anywhere in the world.</p>

<h3 class="mb-3"><strong>Heroku</strong></h3>

   <p>Heroku is a software platform that allows us to run and operate web applications in the cloud</p>
    
         <p>Follow the next steps to deploy the Django web app on Heroku.</p>


         <p>Go to <a class="text-primary"  href="https://www.heroku.com/" target="_blank">Heroku</a> and create an account.</p>


         <p>We also need Heroku CLI, so go to <a class="text-primary" href="https://devcenter.heroku.com/articles/heroku-cli" target="_blank">Heroku CLI</a> and follow the instructions to install it.</p>


         <p>Issue the following commands in the terminal.</p> 


         <pre className="code-box">
<p>pip install django-heroku</p>
<p>pip install gunicorn</p>


</pre>

<p>These packages handle the configuration needed to deploy the web app on Heroku. You can read more about them <a class="text-primary"  href="https://devcenter.heroku.com/categories/python-support" target="_blank">here</a>.</p>

        <p>Issue the following command to create a <code className="code-text">requirements.txt</code> file.</p>



<pre className="code-box">
<p>pip freeze > requirements.txt</p>

</pre>


<p>After issuing the command above, a <code className="code-text">requirements.txt</code> file should appear in the project directory.</p>
<p>This file lets Heroku know which packages the web application requires, so that it can run properly on Heroku.</p>
<p>Click on this file to see all packages that we have installed so far.</p>


<pre className="code-box"><p>#project/requirements.txt</p>

<p>asgiref==3.3.1</p>
<p>beautifulsoup4==4.9.3</p>
<p>boto3==1.17.93</p>
<p>botocore==1.20.93</p>
<p>crispy-bootstrap5==0.4</p>
<p>dj-database-url==0.5.0</p>
<p>Django==3.1.6</p>
<p>django-bootstrap-v5==1.0.4</p>
<p>django-crispy-forms==1.12.0</p>
<p>django-filter==2.4.0</p>
<p>django-heroku==0.3.1</p>
<p>django-storages==1.11.1</p>
<p>gunicorn==20.1.0</p>
<p>importlib-metadata==3.10.1</p>
<p>jmespath==0.10.0</p>
<p>Pillow==8.1.0</p>
<p>psycopg2==2.8.6</p>
<p>psycopg2-binary==2.8.6</p>
<p>python-dateutil==2.8.1</p>
<p>pytz==2021.1</p>
<p>s3transfer==0.4.2</p>
<p>six==1.16.0</p>
<p>soupsieve==2.2</p>
<p>sqlparse==0.4.1</p>
<p>urllib3==1.26.4</p>
<p>whitenoise==5.2.0</p>
<p>zipp==3.5.0</p>
</pre>


<p>Some packages may vary, but if you have been following the book you should be fine.</p>

<p>Create a <code className="code-text">runtime.txt</code> file in the project directory and add the version of Python that you are currently using.</p>



<pre className="code-box">
<p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>├── media</p>
<p>├── users</p>
<p>├── venv</p>
<p>├── manage.py</p>
<p>├── requirements.txt</p>
<p>└── runtime.txt</p>
</pre>


<p>To check the version of Python that you are using, issue the following command.</p>

       <pre className="code-box">
<p>python --version</p>

</pre> 


<p>Add the Python version to the <code className="code-text">runtime.txt</code> file.</p>

<pre className="code-box">
<p>#project/runtime.txt</p>
<p>python-3.9.6</p>
</pre> 


<p>Create another file called <code className="code-text">Procfile</code>(no file extension) in the project directory and add the following code.</p>

<pre className="code-box">
<p>#project/Procfile</p>
<p>web: gunicorn example.wsgi --log-file -</p>
</pre> 


<p>This file lets Heroku know which processes are needed to serve the project. 
  Make sure that you include the name of your project.</p>
  <p>Usually the directory that contains the <code className="code-text">settings.py</code> file is the project folder, in my case, <code className="code-text">example</code>.</p>

  <p>Open <code className="code-text">settings.py</code> and make the following changes to the code.</p>

  <pre className="code-box">
<p>#example/settings.py</p>
<p>import django_heroku</p>
<p>...</p>
<p># SECURITY WARNING: don't run with debug turned on in production!</p>
<p>DEBUG = False</p>
<p>...</p>
<p>django_heroku.settings(locals())</p>
</pre> 


<p>We are about to deploy the project on Heroku, so change the <code className="code-text">DEBUG</code>
    setting to False. 

    </p>

    <p>We have also added the lines of code needed to import Heroku.</p>

    <p>Check if you have Git installed. Git allows us to keep track of changes made to the project.</p>

       
    <p>Issue the following command to see if you have Git installed.</p>

    <pre className="code-box">
<p>git --version</p>
</pre> 

<p>If you don’t have it, please download <a class="text-primary"  href="https://git-scm.com" target="_blank">Git</a> and install it.</p>
  
 
  <p>After installing Git, issue the following commands to set your credentials, so that Git knows which user is making the changes to the project. Use your own credentials.</p>

  <pre className="code-box">
<p>git config --global user.name</p>
<p>"admin"</p>
<p>git config --global user.email</p>
<p>"admin@gmail.com"</p>
</pre> 

<p>Create a file called <code className="code-text">.gitignore</code> in the project directory and add the following code.</p>


<pre className="code-box">
<p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>├── media</p>
<p>├── users</p>
<p>├── venv</p>
<p>├── manage.py</p>
<p>├── requirements.txt</p>
<p>├── runtime.txt</p>
<p>├── Procfile</p>
<p>└── .gitignore</p>
</pre>

<pre className="code-box">
<p>#project/.gitignore</p>
<p>venv/</p>
<p>__pycache__/</p>
</pre>

<p>We don't need to track all files, so we ignore the entire <code className="code-text">venv</code> directory and the <code className="code-text">__pycache__</code>
    directory which contains .pyc files that are created automatically when
    Django runs the project.</p>
    

    <p>Issue the following commands to start deploying the app.</p>

    <pre className="code-box">
<p>git init</p>
<p>*Initialized empty Git repository in /home/admin/…/.git/*</p>
<p>git add .</p>
<p>git commit -am “Ready for deployment” </p>
<p>*A bunch of files will be created after issuing the command above.*</p>
<p>git status </p>
<p>*On branch master nothing to commit, working tree clean*</p>
</pre> 

    <p>Issue the following commands to push the project to Heroku.</p>


    <pre className="code-box">
    <p>*heroku: Press any key to open up the browser to login or q to exit:</p>
<p>Logging in... done*</p>
<p>heroku create</p>
<p>*Creating app... done, ⬢ guarded-citadel-37748</p>
<p>https://guarded-citadel-37748.herokuapp.com/|https://git.heroku.com/guarded-citadel-37748.git*</p>
<p>git push heroku master</p>
<p>*Wait for installation</p>
<p>...</p>
<p>remote: Verifying deploy... done.</p>
<p>To https://git.heroku.com/guarded-citadel-37748.git</p>
<p>* [new branch]	master -> master*</p>

</pre> 


<p>Rename the app by using the following command.</p>

<pre className="code-box">
<p>heroku apps:rename bikefindr</p>
<p>*Renaming guarded-citadel-37748 to bikefindr... done*</p>
<p>*https://bikefindr.herokuapp.com/ | https://git.heroku.com/bikefindr.git*</p>
</pre>

<p>Heroku assigns a random name to the web app when the command <code className="code-text">heroku create</code> is issued.</p>



    <p>Go to the Heroku dashboard and click on the app name.</p>


    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image89.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Go to <code className="code-text">Resources</code> > <code className="code-text">Dropdown Menu</code> > <code className="code-text">Delete Add-on.</code>.</p>


    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image90.png"} alt='Django Takeoff Python'  />
    </div>

    <p>We delete the database that comes with Heroku, so that we can set up our own database (Amazon RDS).</p>


<p>Go to <code className="code-text">Settings</code> > <code className="code-text">Reveal Config Vars</code>.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image91.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Open <code className="code-text">settings.py</code> to gather the information needed to complete <code className="code-text">Config Vars</code>.</p>

    <pre className="code-box">
<p>#example/settings.py</p>

<p>...</p>
<p>DATABASES = &#123;</p>
<p> 'default': &#123;</p>
<p>   'ENGINE': 'django.db.backends.postgresql',</p>
<p>   'NAME': 'example_1',</p>
<p>   'USER': 'example', </p>
<p>   'PASSWORD': 'password12345', </p>
<p>   'HOST': 'database-2.c9cggbqhqnex.us-east-2.rds.amazonaws.com',</p>
<p>   'PORT': '5432',</p>
<p>  &#125;</p>
<p>&#125;</p>
<p>...</p>
<p>#S3 BUCKETS</p>
<p>AWS_ACCESS_KEY_ID = 'AKIAWY4F45EVDNFWIXGL' </p>
<p>AWS_SECRET_ACCESS_KEY = 'X9j1UG2z18T6moU17VlUJKwpQ875y3h5kLM+vh5V'</p>
<p>AWS_STORAGE_BUCKET_NAME = 'exampleaws-bucket1'</p>

</pre>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image92.png"} alt='Django Takeoff Python'  />
    </div>

    <p>The first three fields are staightforward. The information for these fields are the two AWS keys and the name of the bucket.</p>

<p>Make sure you use your own AWS keys.</p>



<p>For the <code className="code-text">DATABASE_URL</code> field, follow the format below.</p>

<p><code className="code-text">postgres://username:password@database-endpoint:port/database-name</code></p>

<p>After including the information from <code className="code-text">DATABASES</code>, we have the following.</p>

<p><code className="code-text">postgres://example:password12345@database-2.c9cggbqhqnex.us-east-2.rds.amazonaws.com:5432/example_1</code></p>


<p>Issue the following command to open the app.</p>


<pre className="code-box">
<p>heroku open</p>
</pre>

<p>You can also open the app from the Heroku website.</p>

<p>Check out the website</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image93.png"} alt='Django Takeoff Python'  />
    </div>

    <p>The domain has changed from <code className="code-text">127.0.0.1</code> to <code className="code-text">bikefindr.herokuapp.com</code>.</p>

<p>Test the website by checking out all the pages and features.</p>


<h3  class="mb-3"><strong>Heroku - Custom Domain</strong></h3>

<p>The steps may vary depending on the domain provider that you use, but the process should be similar.</p>


<p>Go to the Heroku dashboard and click on <code className="code-text">Settings</code>.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/Image94.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Scroll down to the <code className="code-text">Domains</code> section and click on <code className="code-text">Add domain</code>.</p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image95.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Add your domain in the following format <code className="code-text">www.yourdomain.com</code> and click <code className="code-text">Next</code>.</p>

    <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image96.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Click again on <code className="code-text">Add domain</code>, add your domain in the following format <code className="code-text">*.yourdomain.com</code>, and click <code className="code-text">Next</code>.</p>

    <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image97.png"} alt='Django Takeoff Python'  />
    </div>
    <p>Click on the edit icon next to the domain with the <code className="code-text">*.yourdomain.com</code> format.</p>
    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image98.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Copy the <code className="code-text">DNS target</code> field.</p>

    <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image99.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Go to the dashboard of your domain provider and look for DNS settings.</p>

<p>The Type field should be <code className="code-text">CNAME</code>, and the Name field should be <code className="code-text">www</code>.</p>
  <p>For the Target field, paste the DNS target, and save changes.</p>

  <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image100.png"} alt='Django Takeoff Python'  />
    </div>

    <p>It usually takes a couple of minutes for the change to take place.
</p>

<p>Open a browser and type your domain, in my case, <code className="code-text">www.bikefindr.com</code>.</p>
  
<p>You should see your website with the domain updated.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image101.png"} alt='Django Takeoff Python'  />
    </div>
    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter21">⬅️ Chapter 21</a></p>
  
</div>

    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter22;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter13 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter12">⬅️ Chapter 12</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter14">Chapter 14 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 13: Foreign Key</h1>
            </div>
        </div>
      </div>
      <p>A foreign key is a way to create relationships between objects. In this case, we'll link listings and users. Each listing will belong to a specific user.</p>

<p>Currently, listings on the website are tied to only one user which is the admin, so we will fix this by adding a foreign key to keep track of which users create which listings.</p>

<p>In order to save time debugging, log into the admin page and delete all users except the admin user.</p>

<p>Go ahead and delete all listings as well.</p>

<p>After you have deleted all users and listings except the admin user, log out of the admin panel.</p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image23.png"} alt='hj'  />
    </div>  

<h3 class="mb-3"><strong>Listings Model - Foreign Key</strong></h3>

              <p>Open<code className="code-text"> models.py </code> from the listings directory and edit the code as shown below.
              </p>


              <pre className="code-box">
<p>#listings/models.py</p>
<p>from django.db import models</p>
<p>from django.utils.timezone import now</p>
<p>from datetime import datetime</p>
<p>from django.contrib.auth.models import User</p>
<p> </p>
<p>class Listings(models.Model):</p>
  <p>&nbsp;class SaleType(models.TextChoices):</p>
   <p>&nbsp; &nbsp; &nbsp;PICK_UP = "Available for pickup"</p>
   <p>&nbsp; &nbsp; &nbsp;SHIP = "Available for shipping"</p>
   <p> </p>
   <p>&nbsp;class ConditionType(models.TextChoices): </p>
   <p>&nbsp; &nbsp; &nbsp;USED = "Used"</p>
   <p>&nbsp; &nbsp; &nbsp;NEW = "New"</p>
   <p> </p>
   <p>&nbsp;class ProductType(models.TextChoices):</p>
   <p>&nbsp; &nbsp; &nbsp;BIKE= "Bike"</p>
   <p>&nbsp; &nbsp; &nbsp;PARTS = "Parts" </p>
   <p>&nbsp; &nbsp; &nbsp;OTHER = "Other"</p>
   <p> </p>
   <p>&nbsp;user = models.ForeignKey(User, null=True, on_delete=models.CASCADE)</p>
   <p>&nbsp;title = models.CharField(max_length=100)</p>
  
   <p>&nbsp;condition = models.CharField(</p>
   <p>&nbsp;condition = models.CharField(</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;max_length=50, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;choices=ConditionType.choices,</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;default=ConditionType.USED)</p>

   <p>&nbsp;product_type = models.CharField(</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;max_length=50, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;choices=ProductType.choices, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;default=ProductType.BIKE)</p>

   <p>&nbsp;sale_type = models.CharField(</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;max_length=50, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;choices=SaleType.choices, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;default=SaleType.SHIP)</p>

   <p>&nbsp;price = models.FloatField()</p>
   <p>&nbsp;city = models.CharField(max_length=100) </p>
   <p>&nbsp;state = models.CharField(max_length=100) </p>
   <p>&nbsp;zipcode = models.CharField(max_length=100) </p>
   <p>&nbsp;main_photo = models.ImageField()</p>
   <p>&nbsp;photo_1 = models.ImageField()</p>
   <p>&nbsp;photo_2 = models.ImageField(blank=True) </p>
   <p>&nbsp;list_date = models.DateTimeField(default=now) </p>
   <p>&nbsp;contact_email = models.CharField(max_length=50)</p>
  
   <p>&nbsp;def __str__(self): </p>
   <p>&nbsp;&nbsp;&nbsp;return self.title</p>
   
   <p>&nbsp;class Meta:</p>
   <p>&nbsp;&nbsp;&nbsp;verbose_name_plural = "Listings"</p>
   
   </pre>



              <p>
              We have linked the <code className="code-text">Listings</code> model and the <code className="code-text">User</code> model by using <code className="code-text">models.ForeignKey</code>.
              </p>
              <p>
              <code className="code-text">null=True</code> makes it so that the field accepts NULL values.
              </p>
              <p>
              <code className="code-text">on_delete=models.CASCADE</code> means that if we delete a user, all listings created by that user will be deleted as well just like a cascade effect.
              </p>

              <h3 class="mb-3"><strong>Listings View - Foreign Key</strong></h3>
<p>There’s one more change that we have to make, so that listings are assigned to specific users.</p>
<p>Open<code className="code-text"> views.py </code> from the listings directory and edit the code as shown below.
</p>



<pre className="code-box">
<p>#listings/views.py</p>
<p> </p>
<p>from django.shortcuts import render, redirect</p>
<p>from .forms import ListingForm</p>
<p>from .models import Listings</p>
<p>...</p>
<p>def new_listing(request):</p>
<p>    if request.method != 'POST':</p>
<p>        form = ListingForm()</p>
<p>    else:</p>
<p>        form = ListingForm(request.POST, request.FILES)</p>
<p>        if form.is_valid():</p>
<p>           instance = form.save(commit=False)</p>
<p>           instance.user = request.user</p>
<p>           instance.save()</p>
<p>           return redirect('listings:all_listings')</p>
<p> </p>
<p>    context = &#123;'form': form&#125;</p>
<p>    return render(request, 'listings/new_listing.html', context)</p>
<p>...</p>
</pre>


<p>The <code className="code-text">instance</code> will hold the form data, but we include <code className="code-text">commit=False</code>, so that we don't commit changes to the database yet.</p>


<p><code className="code-text">request.user</code> gives us the current logged-in user. This user will be tied to the listing being created. Once we have the user, proceed to save the form by using <code className="code-text">instance.save()</code>. This time changes will be committed to the database.</p>

<h3 class="mb-3"><strong>Testing - Foreign Key</strong></h3>


<p>Since we have edited the <code className="code-text">Listings</code> model, we have to issue the migration commands first, and then run the server.</p>


<pre className="code-box">
<p>python manage.py makemigrations</p>
<p>python manage.py migrate</p>
<p>python manage.py runserver</p>
</pre>

<p>To check that the foreign key has been created successfully, create two users (user 1 and user 2).</p>

<p>With each new user and the admin user, create at least one listing through the new listing form.</p>

<p>Go to the admin panel > <code className="code-text">Listings</code></p>

<p>
Click on the new listings created. The listings should show the users who created them at the top. Each user is assigned automatically when creating listings.</p>

<h5 class="mb-3"><strong>Admin User</strong></h5>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image25.png"} alt='hj'  />
    </div> 

    <h5 class="mb-3"><strong>User1</strong></h5>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image26.png"} alt='hj'  />
    </div>

    <h5 class="mb-3"><strong>User2</strong></h5>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image27.png"} alt='hj'  />
    </div>

    <h3 class="mb-3"><strong>My Listings View - Foreign Key</strong></h3>

<p>
Currently, the code for the listings view and my listings view is the same. If you log in as any of
the users and go to my listings page, you see all listings even the ones that don’t belong to the current logged-in user.</p>

<p>Open <code className="code-text">views.py</code> and change my listings view.</p>

<pre className="code-box">
<p>#listings/views.py</p>
<p> </p>
<p>from django.shortcuts import render, redirect</p>
<p>from .forms import ListingForm</p>
<p>from .models import Listings</p>
<p>...</p>
<p>def my_listing(request):</p>
<p>    my_listings = request.user.listings_set.order_by('-list_date')</p>
<p> </p>
<p>    context = &#123;'my_listings': my_listings&#125;</p>
<p>    return render(request, 'listings/my_listings.html', context)</p>
<p>...</p>
</pre>

<p>We have edited my listings view to show only the listings posted by the current logged-in user.</p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>

<p>Go to my listings page. You should only see one listing unless you created more with that particular user.</p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image28.png"} alt='hj'  />
    </div>

    <h3 class="mb-3"><strong>Navbar - Foreign Key</strong></h3>

<p>
We will hide my listings page to unregistered users. Only users who have accounts will be able to
access this page and create listings.</p>

<p>Open <code className="code-text">base.html</code> and edit the code as shown.</p>

<pre className="code-box">
<p>&#60;--listings/templates/listings/base.html--&#62;</p>
<p> </p>
<p>&#60;nav class='navbar'&#62;</p>
<p>        &#60;a href="&#123;% url 'listings:index' %&#125;"&#62;BIKE FINDER&#60;/a&#62;-</p>
<p>        &#60;a href="&#123;% url 'listings:all_listings' %&#125;"&#62;LISTINGS&#60;/a&#62;-</p>
<p> </p>
<p>        &#123;% if user.is_authenticated %&#125;</p>
<p>        &#60;a href="&#123;% url 'listings:my_listings' %&#125;"&#62;MY LISTINGS&#60;/a&#62;-</p>
<p>        &#123;&#123; user.username &#125;&#125;-</p>
<p>        &#60;a href="&#123;% url 'users:logout' %&#125;"&#62;LOGOUT&#60;/a&#62;</p>
<p>        &#123;% else %&#125;</p>
<p>        &#60;a href="&#123;% url 'users:login' %&#125;"&#62;LOGIN&#60;/a&#62;</p>
<p>        &#60;a href="&#123;% url 'users:register' %&#125;"&#62;REGISTER&#60;/a&#62;</p>
<p>        &#123;% endif %&#125;</p>
<p>&#60;/nav&#62;</p>

<p>&#123;% block content%&#125;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>

<p> We have moved my listings link inside the if statement, so the link only appears if the user is
 logged in.</p>


    <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image29.png"} alt='hj'  />
    </div>   

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter12">⬅️ Chapter 13</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter14">Chapter 14 ➡️ </a></p>
</div>


    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter13;

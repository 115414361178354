import { Typography, Button} from '@material-ui/core';
import React from 'react';
import "./index.css";




const Header = () => {


    return(


       <>
        <div className='overall'>
        <div className="hero-container">
          <div className="hero-container-left">
            <h2 className="title-left">
              <span className='span-left'>
                <Typography style={{ fontSize: "56px"}}>
                Welcome to 
                </Typography>
               
              </span>
              <span className='span-left2'>
                <Typography style={{ fontSize: "48px"}}>
                  DEVBALT!
                </Typography>
              </span>
            </h2>
            <p className='headline-title'>
            <Typography style={{ fontSize: "24px"}}>
             Web Development Content to Take your Career to New Heights 
             </Typography>
            </p>
            <div className='button-container'>
              <button className="btn-filled-orange">
              <a href="/takeoff">
                Get Started
              </a>
              </button>
             
            </div>
          </div>
          <div class="hero-container-right">
            <div className='image-right'>
              
            <img className="illustration" src={"images/illustration.svg"} alt='web development'/>
           
            </div>
          </div>
        </div>
        </div>





    

</>
        
  
      
    )
}

export default Header;
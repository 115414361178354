import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../theme/theme"
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Content from "../components/Content";
import TableContents from "../components/TableContents";




const Takeoff = () => {


  return (

    
  <ThemeProvider theme={theme}>
    <Navbar />
    
    

    <TableContents/>
    
  </ThemeProvider>
  );
};

export default Takeoff;

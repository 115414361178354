import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter21 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter20">⬅️ Chapter 20</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter22">Chapter 22 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 21: Amazon S3 Buckets</h1>
            </div>
        </div>
      </div>

      <p>Amazon S3 buckets is a service that allows us to serve static files including images from a bucket provided by Amazon.</p>
   <p>Instead of serving images locally from our Django project (not recommended for production), we will use this Amazon service.</p>
    
         <p>Go to the detail listing page for one of the listings.</p>
           <p>Control-click/right-click on any of the images > <code className="code-text">Inspect Element</code>.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image70.png"} alt='Django'  />
    </div>

    <p>The path of all images start like this <code className="code-text">/media/...jpg</code>. This is the path to the media folder of the local Django project.</p>
                

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image71.png"} alt='Django'  />
    </div>


    <p>By the end of this chapter,
                 the path will change to show all images being served from Amazon.</p>

                 <h3 class="mb-3"><strong>Amazon S3 Buckets Setup</strong></h3>

<p>To create an Amazon S3 bucket, follow these steps.</p>


              <p>Go to AWS (Amazon Web Services) and log in with your credentials.</p>

           

             

    
  <p>Search for S3 and select it.</p>

 

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image72.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Click on <code className="code-text">Create bucket</code>.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image73.png"} alt='Django Takeoff Python'  />
    </div>

<p>Choose a bucket name. I have chosen <code className="code-text">exampleaws-bucket1</code> for my bucket.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image74.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Keep the default settings for the other sections.</p>

<p>Scroll all the way down to the bottom and click on
<code className="code-text"> Create bucket
  </code>.</p>

  <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image75.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Click on the bucket name to see more details about the bucket.</p>


    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image76.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Click on <code className="code-text">Permissions</code>.</p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image77.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Look for the <code className="code-text">CORS configuration</code> section, click on <code className="code-text">Edit</code> and add the following code.</p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image78.png"} alt='Django Takeoff Python'  />
    </div>


    <pre className="code-box">
<p>[</p>
<p>  &#123;</p>
<p>   "AllowedHeaders": [</p>
<p>     "*"</p>
<p>   ],</p>
<p>   "AllowedMethods": [</p>
<p>     "PUT",</p>
<p>     "POST",</p>
<p>     "DELETE"</p>
<p>   ],</p>
<p>   "AllowedOrigins": [</p>
<p>     "*"</p>
<p>   ],</p>
<p>   "ExposeHeaders": []</p>
<p>  &#125;</p>
<p>]</p>
</pre>

<p>After you add the code, click on <code className="code-text">Save changes</code>.</p>

<h3 class="mb-3"><strong>Amazon IAM (Identity and Access Management)</strong></h3>

  <p>In order to manage the Amazon S3 bucket, we need to create a user in AWS.</p>


  <p>Go to the homepage of AWS, search for IAM, and select it.</p>


  <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image79.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Click on <code className="code-text">Users</code>.</p>


    <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image80.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Click on <code className="code-text">Add users</code>.</p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image81.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Choose a username. I have chosen <code className="code-text">owner</code> as the name.</p>
    
    <p>For access type, select <code className="code-text">Programmatic access</code>.</p>
    <p>Click on <code className="code-text">Next: Permissions</code>.</p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image82.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Click on <code className="code-text">Attach existing policies directly</code> > Search for <code className="code-text">S3</code> > Select <code className="code-text">AmazonS3FullAccess</code>
    > Click on <code className="code-text">Next:Tags</code>.</p>


    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image83.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Skip the <code className="code-text">Tags</code> section, and go to the last section.</p>
        

        <p>Review the information and click on <code className="code-text">Create user</code>.</p>
        
        <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image84.png"} alt='Django Takeoff Python'  />
    </div>

    <p>After you create a user, two keys will be generated. We will include them in the
            <code className="code-text"> settings.py</code> file of the project.
            </p>

            <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image85.png"} alt='Django Takeoff Python'  />
    </div>
    <h3 class="mb-3"><strong>Connecting to Amazon S3 Buckets</strong></h3>
        <p>Open <code className="code-text">settings.py</code> and add the following code at the bottom of the file.
            </p>


            <pre className="code-box">
<p>#example/settings.py</p>
<p>...</p>
<p>#S3 BUCKETS</p>
<p>AWS_ACCESS_KEY_ID = 'AKIAWY4F45EVDNFWIXGL' </p>
<p>AWS_SECRET_ACCESS_KEY = 'X9j1UG2z18T6moU17VlUJKwpQ875y3h5kLM+vh5V'</p>
<p>AWS_STORAGE_BUCKET_NAME = 'exampleaws-bucket1'</p>

</pre>

<p>The generated keys will be different, so insert your own keys in the code above.</p>


<p>Install the following packages to get Amazon S3 Buckets working with the Django project.
</p>


<pre className="code-box">
<p>pip install django-storages</p>
<p>pip install boto3</p>


</pre>

<p>Open<code className="code-text"> settings.py </code>
    and add <code className="code-text">storages</code> to the <code className="code-text">INSTALLED_APPS</code> section.</p>
  <p>Also, add the additional lines of code shown below to complete the configuration.</p>

  <pre className="code-box">
<p>#example/settings.py</p>

<p>...</p>
<p>INSTALLED_APPS = [</p>
<p>   'django.contrib.admin',</p>
<p>   'django.contrib.auth',</p>
<p>   'django.contrib.contenttypes',</p>
<p>   'django.contrib.sessions',</p>
<p>   'django.contrib.messages',</p>
<p>   'django.contrib.staticfiles',</p>
<p>   'listings',</p>
<p>   'users',</p>
<p>   'django_filters'</p>
<p>   'crispy_forms',</p>
<p>   'crispy_bootstrap5'</p>
<p>   'storages'</p>
<p>]</p>

<p>...</p>
<p>#S3 BUCKETS</p>
<p>AWS_ACCESS_KEY_ID = 'AKIAWY4F45EVDNFWIXGL' </p>
<p>AWS_SECRET_ACCESS_KEY = 'X9j1UG2z18T6moU17VlUJKwpQ875y3h5kLM+vh5V'</p>
<p>AWS_STORAGE_BUCKET_NAME = 'exampleaws-bucket1'</p>

<p> </p>
<p>AWS_S3_FILE_OVERWRITE = False</p>
<p>AWS_DEFAULT_ACL = None </p>
<p>AWS_S3_REGION_NAME = 'us-east-2'</p>
<p>DEFAULT_FILE_STORAGE = 'storages.backends.s3boto3.S3Boto3Storage'</p>
<p>STATICFILES_STORAGE = 'storages.backends.s3boto3.S3Boto3Storage'</p>
</pre>


<p>If you want to know more about the Amazon S3 Buckets configuration, refer to the resources below.</p>

<p>
     <a class="text-primary"  href="https://django-storages.readthedocs.io/en/latest/" target="_blank">Django Storages</a>
  </p>
<p><a class="text-primary"  href="https://django-storages.readthedocs.io/en/latest/backends/amazon-S3.html" target="_blank">Amazon S3</a>
</p>


<p>Go to the dashboard of Amazon S3 Buckets and click on <code className="code-text">Upload</code>.
</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image86.png"} alt='Django Takeoff Python'  />
    </div>

    <p>Search for the <code className="code-text">media</code> folder of the Django project on your computer and upload the entire folder or the individual images.</p>

<p>In my case, there is only one listing, so only two images to upload.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image87.png"} alt='Django Takeoff Python'  />
    </div>


    <p>We only have to follow this process once. 
     After we set up the Amazon S3 bucket, the images for the project will be uploaded directly to the bucket.</p>
    <p>If you have any other static files such as CSS files, logos, and images, upload them here too.</p>

    <p>Issue the <code className="code-text">python manage.py runserver</code> command and go to the detail listing page of any of the listings.
    </p>

    <p>Control-click/right-click on any of the images > <code className="code-text">Inspect Element</code>.
    </p>

    <p>The new path of the images should show a URL with the name of your bucket followed by <code className="code-text">.s3.amazonaws.com</code>.
    </p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image88.png"} alt='Django Takeoff Python'  />
    </div>


    <p>All future images will be uploaded to the bucket instead of the media folder of the Django project.</p>

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter20">⬅️ Chapter 20</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter22">Chapter 22 ➡️ </a></p>
</div>
    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter21;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter8 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter7">⬅️ Chapter 7</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter9">Chapter 9 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 8: My Listings Page</h1>
            </div>
        </div>
      </div>
      <p>In this chapter, we will create a page where users will be able to manage their own listings. They will be able to add, edit, and delete listings from this page.

              </p>

              <h3 class="mb-3"><strong>My Listings URL</strong></h3>
              <p>Open<code className="code-text"> urls.py </code>add the path to my listings page.
              </p>


<pre className="code-box">
<p>#listings/urls.py</p>

<p>from django.urls import path</p>
<p>from .import views</p>

<p>app_name = 'listings'</p>
<p>urlpatterns = [</p>
             <p>   path(' ', views.index, name='index'),</p>
             <p>   path('all_listings/', views.all_listings, name='all_listings'),</p>
             <p>   path('new_listing/', views.new_listing, name='new_listing'),</p>
             <p>   path('all_listings/&#60;detail_id&#62;/', views.detail, name='detail'),</p>
             <p>   path('my_listings/', views.my_listings, name='my_listings'),</p>
             <p>]</p>


</pre>


<h3 class="mb-3"><strong>My Listings View</strong></h3>
              <p>Open<code className="code-text"> views.py </code> and create the view for my listings page.
              </p>


              <pre className="code-box">
<p>#listings/views.py</p>
<p> </p>
<p>from django.shortcuts import render, redirect</p>
<p>from .forms import ListingForm</p>
<p>from .models import Listings</p>
<p>...</p>
<p>def my_listings(request, detail_id):</p>
<p>    my_listings = Listings.objects.order_by('-list_date')</p>

<p>    context = &#123;'my_listings': my_listings&#125;</p>
<p>    return render(request, 'listing/my_listings.html', context)</p>
</pre>
         

<p>As of now, my listings view is just a copy of the all listings view. This is the case because we
haven't created users yet, so at this point all listings belong to one person (admin).</p>

<p>Once we have different users, we will come back to fix this view.</p>


<h3 class="mb-3"><strong>My Listings Template</strong></h3>
              <p>Create a template called<code className="code-text"> my_listings.html </code> in the templates folder and add the following code.
              </p>



              <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ ├── index.html</p>
<p>│ │ ├── all_listings.html</p>
<p>│ │ └── new_listing.html</p>
<p>│ │ └── detail.html</p>
<p>│ │ └── my_listings.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>



<pre className="code-box">
<p>&#60;--listings/templates/listings/my_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h2&#62;MY LISTINGS:&#60;/h2&#62;</p>

<p>&#60;div class="row"&#62;</p>
<p>  &#60;div class="col"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>     &#60;table class="table"&#62;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;th&#62;Product&#60;/th&#62;</p>
<p>         &#60;th&#62;Date Listed&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>       &#60;tr&#62;</p>
<p>       &#123;% for my_listing in my_listings %&#125;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.title &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.list_date &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn"</p>
<p>           href="&#123;% url 'listings:detail' my_listing.id %&#125;"&#62;View</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn" href=""&#62;Edit&#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn" href=""&#62;Delete&#60;/a&#62;&#60;/td&#62;</p>
<p>       &#60;/tr&#62;</p>
<p>       &#123;% endfor %&#125;</p>
<p>      &#60;/table&#62;</p>
<p>       &#60;div class="container"&#62;</p>
<p>         &#60;a class="btn"&#62;</p>
<p>           href="&#123;% url 'listings:new_listing' %&#125;"&#62;New Listing</p>
<p>         &#60;/a&#62;</p>
<p>       &#60;/div&#62;</p>
<p>     &#60;/div&#62;</p>
<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>

<p>If users want to see more details about any of their listings, they can click on 'View'.</p>

<p>Also, we added a link to the new listing page, so that users can create listings from my listings
page rather than having the link on the navbar.</p>

<h3 class="mb-3"><strong>My Listings - Navbar</strong></h3>
          
<p>Open <code className="code-text">base.html</code> and change the code as shown below.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/base.html--&#62;</p>

<p>&#60;nav class="navbar"&#62;</p>
<p>         &#60;a href="&#123;% url 'listings:index' %&#125;"&#62;BIKE FINDER&#60;/a&#62;-</p>
<p>         &#60;a href="&#123;% url 'listings:all_listings' %&#125;"&#62;LISTINGS&#60;/a&#62;-</p>
<p>         &#60;a href="&#123;% url 'listings:my_listings' %&#125;"&#62;MY LISTINGS&#60;/a&#62;</p>
<p>&#60;/nav&#62;</p>
<p>&#123;%block content%&#125;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>


<p>After saving the changes, the navbar should have MY LISTINGS instead of NEW LISTING.</p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver </code> command.</p>

<p>Visit my listings page and make sure everything checks out. The two links, edit and delete, are disabled for now.</p>

   <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image15.png"} alt='hj'  />
    </div>  


    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter7">⬅️ Chapter 7</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter9">Chapter 9 ➡️ </a></p>
</div>
    </div>



  
   
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter8;

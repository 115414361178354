import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter1 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/">Home 🏠</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter2">Chapter 2 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 1 : Django Web App Setup</h1>
            </div>
        </div>
      </div>
      <h2>Prerequisites</h2>

<ul>
  <li>Any computer.</li>
  <li>Python 3.0 or greater.</li>
  <li>Any code editor (Visual Studio Code used in this book).</li>
  <li>Any database (PostgreSQL used in this book).</li>
</ul>

<p>Django uses SQLite3 database by default, so you can skip the next section if you are planning to use that database.</p>

<h3>PostgreSQL Database Installation</h3>

<p>If you plan to use PostgreSQL, follow the steps below.</p>

<ol>
  <li>Download and install <a href="https://www.postgresql.org/download/">PostgreSQL</a></li>
  <li>Download and install <a href="https://www.pgadmin.org/download/">PgAdmin4</a></li>
  <li>Open PostgreSQL database.</li>
  <li>Click on the postgres database.</li>
</ol>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image1.png"} alt='hj'  />
    </div>  

<p>The command window will appear. Issue the following commands.</p>

<pre className="code-box">
<p>postgres=#</p>
<p>postgres=# \password</p>
<p>Enter new password: ******</p>
<p>Enter it again: ******</p>
<p>postgres=# CREATE DATABASE example OWNER postgres;</p>
</pre>


</div>



    <div class="container">
      <table class="table table-sm table-bordered mytable">

        <thead class="thead-light">
          <tr>
            <th scope="col-4"><p className="table-title">Command</p></th>
            <th scope="col-8"><p className="table-title">Explanation</p></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th scope="col-4"><p class="table-left"><code className="code-text">\password</code></p></th>
            <th scope="col-8"><p class="table-left">Creates database password.</p></th>
          </tr>
          <tr>
            <th scope="col-4"><p class="table-right"><code className="code-text">CREATE DATABASE dbname OWNER
              rolename;</code></p>
              </th>
            <th scope="col-8">
            <p class="table-right">Creates a new database.</p>
            <p class="table-right">For this project, <code className="code-text">dbname = example</code> and <code className="code-text">rolename = postgres</code></p>
              </th>
          </tr>
        </tbody>

      </table>
    </div>

    <div class="container" >
    <h3 class="mb-3" id="visual"><strong>Visual Studio and Packages Installation</strong></h3>
     <p>Start by creating a folder to store all the project files. I created my folder named <code className="code-text">project</code> in the
        <code className="code-text"> Documents</code> folder of my computer. You can create it anywhere on your computer.
      </p>
      <p>In Visual Studio Code, open the project folder created in the previous
         step by clicking on <code className="code-text">File > Open</code> and then search for the folder.
      </p>
  
      <p>After you have opened the <code className="code-text">project </code>folder, open a terminal window by going to <code className="code-text">Terminal > New Terminal</code>.
        A terminal window will appear at the bottom of Visual Studio Code.
      </p>
        
      <p>Make sure you are inside the <code className="code-text">project</code> folder by inspecting the path in the terminal. 
        The path should have the name of the folder attached at the end such as <code className="code-text">...project % </code>
      </p>

    <h4 class="mb-3"><strong>Virtual Environment</strong></h4>
      <p>A virtual environment <code className="code-text"> venv</code> helps maintain individual projects relatively contained
         in order to avoid installing Python packages globally on our computer which might cause problems
          between conflicting files.
      </p>
      <p>To create a virtual environment and activate it,
         issue the following commands in the terminal window.
      </p>
     
      <pre className="code-box">
<p>python3 -m venv venv </p>
<p>source venv/bin/activate</p>
</pre>


<p>After the commands have been issued, check the path in the terminal.
        The path should look like this <code className="code-text">(venv)...project % </code></p>
        <p>A<code className="code-text">venv</code> directory will be created after issuing the commands.
           Look for this directory on the sidebar of Visual Studio Code.</p>


        <p>Now that the virtual environment has been created successfully, start installing the packages needed for the project.
          There are multiple ways to install packages. We will use the <code className="code-text">pip</code> install command in this book.</p>

      <h5 class="mb-3"><strong>Pip</strong></h5>
        <p>Pip is the standard package-management system for Python.
           Pip handles the installation of packages from the command-line interface.
        </p>
        <p>Issue the following commands in the terminal window.</p>

        <pre class="code-box">
 <p>pip install Django </p>
 <p>pip install Pillow</p>
<p>pip install psycopg2</p>
<p>pip install psycopg2-binary</p>
 </pre>
 <h5 class="mb-3"><strong>Django</strong></h5>

<p>Python web framework used to develop web applications in a fast and secure way. Django is free and open source.
   It allows for rapid prototyping while maintaining a secure and reliable structure.
    It’s also good for scalability.
</p>

<h5 class="mb-3"><strong>Pillow</strong></h5>

<p>Python library that allows to manipulate, upload, and save different image file formats. 
   This library adds the basic image processing capabilities for any website to handle image files.
</p>

<h5 class="mb-3"><strong>Psycopg2 & Psycopg2-binary</strong></h5>

<p>Libraries that help connect to PostgreSQL database. <code className="code-text">Psycopg2-binary </code>is ideal for development and testing.
   If you don’t want to move this project to Heroku by the end of the book, you can install this library only.
   I plan to move this project to Heroku, so I will also need the<code className="code-text"> psycopg2 </code>library.
</p>
<p>If you’re using the SQLite3 database, then you don’t need to install these packages.
</p>

<h5 class="mb-3"><strong>Troubleshooting Psycopg2 Installation</strong></h5>
                      
<p>If after issuing <code className="code-text">pip install psycopg2</code>, you get an error like the one shown below.
 Follow the next steps to troubleshoot.
</p>


<pre class="code-box">
<p>failed with error code 1 in /private/var/folders/fz/lf7pz3kj0pj_c0dxvw9lk40h0000gn/T/pip- install 3sa8h9hx/psycopg2/</p></pre>


<p>Issue <code className="code-text">pg_config </code>in the terminal window. Look for the <code className="code-text">LDFLAGS</code> path, 
  copy everything after the equal sign, and paste it in between the quotes in the next command as shown below.</p>

  <pre class="code-box">
    <p>pg_config</p>
    <p>*copy LDFLAGS path*</p>
    <p>env LDFLAGS="paste path here" pip install psycopg2</p></pre>

       
        <p>Don't forget to add <code className="code-text">pip install psycopg2</code> after pasting the path and check for extra spaces.
     Sometimes, an extra space can mess up the entire installation.
  </p>

  <p>The problem with psycopg2 should be fixed after this process.
  </p>

  <p>In the world of programming, debugging is unavoidable, so it's good to get used to it.</p>
    <p>The best resource to use when stuck is the official <a class="text-primary"href="https://docs.djangoproject.com/en/3.2/" target="_blank">Django</a> documentation. 
     If you don’t find your solution there, start by searching your questions on Google or Stack Overflow.</p>
  <p>Keep in mind there's not just one way to do things. Usually, 
    there are multiple ways to solve a problem and everyone encounters different problems.</p>


    <div class="container ">
    <table class="table table-sm table-bordered mytable">
       <thead class="thead-light">
          <tr>
              <th scope="col-4"><p class="table-title">Command</p></th>
              <th scope="col-8"><p class="table-title">Explanation</p></th>
          </tr>
        </thead>
                        
      <tbody>
        <tr>
            <th scope="col-4"><p class="table-left"><code className="code-text">python3 -m venv name</code></p></th>
            <th scope="col-8"><p class="table-left">Creates a virtual environment.</p>
            <p class="l"><code className="code-text">name = venv</code>(virtual environment name)</p>
            </th>
        </tr>

        <tr>
          <th scope="col-4"><p class="l"><code className="code-text">source name/bin/activate</code></p>
          </th>
          <th scope="col-8">
            <p class="table-right">Activates the virtual environment.</p>
            <p class="table-left"><code className="code-text">name = venv</code></p>
          </th>
        </tr>
                              
        <tr>
          <th scope="col-4"><p class="table-left"><code className="code-text">pip install name</code></p></th>
          <th scope="col-8">
            <p class="table-right">Used to install Python libraries and packages.</p>
            <p class="table-right"><code className="code-text">name = library or package name</code></p>
          </th>
        </tr>
                             
        <tr>
          <th scope="col-4"><p class="table-left"><code className="code-text">pg_config</code></p></th>
          <th scope="col-8">
            <p class="table-right">Retrieves relevant path information about the installed version of PostgreSQL.</p>
          </th>
       </tr>
                            
      </tbody>
    </table>
  </div>




  <h3 class="mb-3" id="listings"><strong>Listings App</strong></h3>
          <p>To create a Django web app, we need to implement a few things.
            Start by issuing the following commands.
          </p>

          <pre class="code-box">
<p>django-admin startproject example .</p>
<p>python manage.py startapp listings</p>
</pre>

<h5 class="mb-3"><strong>django-admin startproject example .</strong></h5>
          <p>Creates a folder with the main Python files needed for the project.
             The name of the project is <code className="code-text">example</code>. You can name your project anything you want.</p>
             
          <p>Don’t forget the dot (.) at the end of the command. 
             The dot (.) creates the folder inside the current working directory.
          </p>


          <div class="container"  >
   <pre className="code-box"><code class="language-html">PROJECT
<p>└── example</p>
<p>│ ├── __pycache__</p>
<p>│ ├── __init__.py</p>
<p>│ ├── asgi.py</p>
<p>│ ├── settings.py</p>
<p>│ ├── urls.py</p>
<p>│ └── wsgi.py</p>
<p>├── listings</p>
<p>├── venv</p>
<p>└── manage.py</p> </code></pre>
</div>

<h5 class="mb-3"><strong>python manage.py startapp listings</strong></h5>
          <p>Creates an app named <code className="code-text">listings</code> which contains other Python files
             needed for the project.
            You can think of apps as small moving parts put together to create an entire machine,
             in this case, the machine is a website. 
          </p>
           <p>In this project, we will have two apps.
              One app to create and post listings on the website 
              and the other to create and register users.
            </p>

            <div class="container"  >
   <pre className="code-box"><code class="language-html">PROJECT
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p> </code></pre>
</div>

<p>
      Open<code className="code-text"> settings.py</code> from the <code className="code-text">example </code>directory 
      and add the listings app to the <code className="code-text">INSTALLED_APPS </code>section.
    </p>

    <pre className="code-box"><code class="language-html">#example/settings.py
<p>...</p>

<p>INSTALLED_APPS = [</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;'django.contrib.admin',</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;'django.contrib.auth',</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;'django.contrib.contenttypes',</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;'django.contrib.sessions',</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;'django.contrib.messages',</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;'django.contrib.staticfiles',</p>
<p>&nbsp;&nbsp;&nbsp;&nbsp;'listings' </p>
<p>]</p>

<p>...</p>
</code></pre>

<p>Every time you create a new app or install packages which are treated like apps,
     you have to register them in <code className="code-text">settings.py</code>.</p> 
  
  <p>
   If you have installed a package and you’re not sure about whether it belongs to the <code className="code-text">INSTALLED_APPS</code> section, check the online documentation for that package.
  </p>

  

<h3 class="mb-3" id="database"><strong>Database Setup</strong></h3>
<h4 class="mb-3"><strong>SQLite3 Database Setup</strong></h4>
  <p>
    The default database used by Django is the SQLite3 database.
     If you are planning to use this database, keep the code below as it is.
     </p>

     <pre className="code-box"><code class="language-html">#example/settings.py
<p>... </p>

<p>DATABASES = &#123; </p>
<p>'default': &#123;  </p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;'ENGINE': 'django.db.backends.sqlite3',</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;'NAME': os.path.join(BASE_DIR, 'db.sqlite3'),</p>
  <p>&nbsp;&nbsp;&#125;</p>
<p>&#125;</p>
<p>...</p>
</code></pre>


<h4 class="mb-3"><strong>PostgreSQL Database Setup</strong></h4>
    <p>
       If planning to use PostgreSQL database, make the following changes.
    </p>


    <pre className="code-box"><code class="language-html">#example/settings.py
<p>... </p>

<p>DATABASES = &#123; </p>
<p>'default': &#123;  </p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;'ENGINE': 'django.db.backends.postgresql',</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;'NAME': 'example',</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;'USER': 'postgres',</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;'PASSWORD': 'password123',</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;'HOST': 'localhost',</p>
  <p>&nbsp;&nbsp;&nbsp;&nbsp;'PORT': '5432',</p>
  <p>&nbsp;&nbsp;&#125;</p>
<p>&#125;</p>
<p>...</p>
</code></pre>

<h5 class="mb-3"><strong>ENGINE</strong></h5>
        <p>
          Database component used to perform CRUD 
          (create, read, update, delete) operations on the database data.
         </p>
       <h5 class="mb-3"><strong>NAME</strong></h5>
        <p>
           Name of the database. The name <code className="code-text">example</code> refers to the name given 
           to the database created earlier in the <a class="ez-toc-link ez-toc-heading-3" href="#dis" title="HTML Table of Contents Example">PostgreSQL Database Installation</a> section.
           If you gave the database a different name, replace it here.
        </p>
       <h5 class="mb-3"><strong>USER</strong></h5>
        <p>                    
          Username of the database. The username is <code className="code-text">postgres</code>. If you chose a different username
           when creating the database, replace that username here.
        </p>
       <h5 class="mb-3"><strong>PASSWORD</strong></h5>
          <p>
         This is the database password. The password used was <code className="code-text">password123</code>. Include your own password if different.
         Refer to the <a class="ez-toc-link ez-toc-heading-3" href="#dis" title="HTML Table of Contents Example">PostgreSQL Database Installation</a>
         section if you are having problems.
         </p>
       <h5 class="mb-3"><strong>HOST</strong></h5>
         <p>
          Refers to the host Django will use when connecting to the database. For the local machine, use <code className="code-text">localhost</code>. If that doesn’t work, try <code className="code-text">127.0.0.1</code>. 
          If the HOST field is left empty, Django will connect using the localhost by default.
         </p>
        <h5 class="mb-3"><strong>PORT</strong></h5>
          <p>
           Refers to which port to use when connecting to the database. In my case, the port is <code className="code-text">5432</code>. 
           If the PORT field is left empty, Django will connect using the default port.
          </p>
          
        <h3 class="mb-3" id="static"><strong>Static and Media Files Setup</strong></h3>
        <h4 class="mb-3"><strong>Static Files</strong></h4>
           <p>
            These files could be images, logos, CSS files, and others.
            </p>
           <p>
            Scroll all the way down to the bottom of <code className="code-text">settings.py</code>. You will see that the <code className="code-text">STATIC_URL</code> is already there. 
            </p>
            <p>
            Add the following code.
           </p>

           <pre className="code-box"><code class="language-html">
            <p>#example/settings.py</p>
            <p>...</p>
                                          
            <p>STATIC_URL = ‘/static/’</p>
            <p>STATICFILES_DIRS = [os.path.join(BASE_DIR, 'static')]</p>
            <p>MEDIA_URL = '/media/'</p>
            <p>MEDIA_ROOT = os.path.join(BASE_DIR, 'media')</p>
            <p>FILE_UPLOAD_PERMISSIONS = 0O640</p>
                                          
            <p>...</p></code></pre>

            <h5 class="mb-3"><strong>
            STATIC_URL
            </strong></h5>
        <p>
          Django will use this path and append it to the base URL
          of the website. For example, (https://www.name_website/static/style.css).
        </p>
        <p>
          Django uses the path as a reference to the location of the static files during development.
        </p>
          <h5 class="mb-3"><strong>
              STATICFILES_DIRS
          </strong></h5>
        <p>
          Django looks into this path for extra static files that might exist for the project.
          You can have different paths listed if you have any additional directories that contain static files.  
        </p>
          <h5 class="mb-3"><strong>
            STATIC_ROOT
          </strong></h5>
          <p>
          Absolute path from where Django will collect static files during production.
          It’s not recommended to serve static files locally during production.
          </p>
          <h5 class="mb-3"><strong>
          Media Files
          </strong></h5>
          <p>
          Usually, these are files uploaded by users.
          </p>
          <h5 class="mb-3"><strong>
          MEDIA_URL
          </strong></h5>
          <p>
          Similar to <code className="code-text">STATIC_URL</code> but refers to media files instead.
          </p>
          <h5 class="mb-3"><strong>
          MEDIA_ROOT
          </strong></h5>
          <p>
          Absolute path to directory which holds user-uploaded files.
          </p>
          <h5 class="mb-3"><strong>
          FILE_UPLOAD_PERMISSIONS
          </strong></h5>
          <p>
          Sometimes, Python throws an error when an image is uploaded.
          In order to avoid inconsistencies with file permissions, please set <code className="code-text">FILE_UPLOAD_PERMISSIONS = 0O640</code>.
          </p>



    <h3 class="mb-3" id="linter"><strong>
      Linter Setup (Optional)
    </strong></h3>
        <p>
          A code linter analyzes your code for potential errors such as syntax, structural, 
          and best practices errors.
          Code linters are useful tools when it comes to learning programming languages.
        </p>
          
        <p>
          Try opening <code className="code-text">urls.py </code>from the project directory. Check for the problems tab in the lower part of the screen. 
          If one of the problems is <code className="code-text">pylint(import-error)</code>, follow the next steps to solve it.
        </p>
        <ul>
          <li class="p_two">
            Go to <code className="code-text">View > Command Palette > Python:SelectLinter > pylint</code>. 
            <p></p>
          </li>
          <li class="p_two">
            After you select pylint, a <code className="code-text">.vscode</code> folder will appear on the sidebar of Visual Studio.
            <p></p>
          </li>
          <li class="p_two">
           Open the<code className="code-text"> settings.json </code>file inside the .vscode folder and make the following changes.
        </li>
      </ul>

           

     
      <h5 class="mb-3"><strong>
        Old settings.json 
      </strong></h5>
      
      <p>
        (You might have different contents in the file).
      </p>

      <pre className="code-box"><code class="language-html">
        <p>#.vscode/settings.json</p>
               
        <p> &#123;</p>
              <p> "python.linting.pylintEnabled": true,</p>
              <p> "python.linting.enabled": true</p>
              <p> &#125;</p></code></pre>

              <h5 class="mb-3"><strong>
        New settings.json
      </strong></h5>

      <pre className="code-box"><code class="language-html">#.vscode/settings.json
               
      <p> &#123;</p>
      <p>  "team.showWelcomeMessage": false,</p>
      <p>  "editor.formatOnSave": true,</p>
      <p>  "python.linting.pycodestyleEnabled": true,</p>
      <p>  "python.linting.pylintArgs": [</p>
      <p>  "--load-plugins",</p>
      <p>  "pylint_django"</p>
      <p>  ],</p>
      <p>  "python.pythonPath": "/usr/bin/python3", </p>
      <p>  "python.linting.pylintEnabled": true,</p>
      <p>  "python.linting.enabled": true</p>
               <p> &#125;</p></code></pre>


               <ul>
        <li class="p_two">
           After saving <code className="code-text">settings.json</code>, the red line under the code should go away.
        </li>
      </ul>
        
      <p>
        To solve the problem, line too long (&gt; 79 characters),
        you can break the single line into two lines.
      </p>
      <p>
      This problem appears because it's best practice to have &lt; 79 characters in each line when writing code in Python. 
      </p>

        <h3 class="mb-3" id="dswa"><strong>
           Django Simple Web App
        </strong></h3>
        <p>
          Run the following commands to see your first Django web app in action.
        </p>

        <pre class="code-box">
<p>python manage.py makemigrations </p>
<p>python manage.py migrate</p>
<p>python manage.py runserver</p>
</pre>

<p>
            If everything went well, you should see a message like the one below.
            If not, please read the error in the terminal and troubleshoot accordingly.
          </p>

          <pre className="code-box"><code class="language-html"><p>System check identified no issues (0 silenced).</p>
          <p>July 29, 2021 - 23:22:19</p>
          <p>Django version 3.2.4, using settings 'example.settings'</p>
          <p>Starting development server at http://127.0.0.1:8000/ </p>
          <p>Quit the server with CONTROL-C.</p>
</code></pre>

<p>
              You should see the following output when going to
               <a class="text-primary" href="http://127.0.0.1:8000" target="_blank">http://127.0.0.1:8000</a>.
            </p>

            <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image2.png"} alt='hj'  />
    </div>  

    <div class="container ">
    <table class="table table-sm table-bordered mytable">

    <thead class="thead-light">
      <tr>
        <th scope="col-4"><p class="table-title">Command</p></th>
        <th scope="col-8"><p class="table-title">Explanation</p></th>
      </tr>
    </thead>

    <tbody>
      <tr>
        <th scope="col-4"><p class="table-left"><code className="code-text">django-admin startproject name .</code></p></th>
        <th scope="col-8"><p class="table-right">Creates a project in the current working directory.</p>
                          <p class="table-right">Important files such as <code className="code-text">settings.py </code>and<code className="code-text"> urls.py </code>will be created.</p>     
                          <p class="table-left"><code className="code-text">name = project name</code></p>
        </th>
      </tr>
      <tr>
        <th scope="col-4"><p class="table-left"><code className="code-text">python manage.py startapp name</code></p>
          </th>
        <th scope="col-8">
         <p class="table-right">Creates an app, essentially, a submodule of the project.</p>
         <p class="table-right"><code className="code-text">name = app name</code></p>
          </th>
      </tr>
      <tr>
        <th scope="col-4"><p class="table-left"><code className="code-text">python manage.py makemigrations</code></p>
          </th>
        <th scope="col-8">
         <p class="table-right">Consolidates new changes made to <code className="code-text">models.py</code>.</p>
          </th>
      </tr>
      <tr>
        <th scope="col-4"><p class="table-left"><code className="code-text">python manage.py migrate</code></p>
          </th>
        <th scope="col-8">

         <p class="table-right">Tables are created in the database when issued.</p>
          </th>
      </tr>
      <tr>
        <th scope="col-4"><p class="table-left"><code className="code-text">python manage.py runserver</code></p>
          </th>
        <th scope="col-8">
         <p class="table-right">Starts a web server on local machine.</p>
         
          </th>
      </tr>
    </tbody>
  </table>

</div>


<h4 class="mb-3"><strong>Projects vs Apps</strong></h4>

<p>
  A project is made up of the different apps and Python files needed to create and run the website.
  On the other hand, an app is a web application which serves a purpose and handles tasks on the website.
</p>

<p>Apps perform specific functions. 
   For example, the listings app will take care of posts on the website while the users app
   will create and register new users.
</p>

<div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/">Home 🏠</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter2">Chapter 2 ➡️ </a></p>
</div>

    </div>

</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter1;

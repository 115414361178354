import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter2 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter1">⬅️ Chapter 1</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter3">Chapter 3 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 2 : Django Web App Setup</h1>
            </div>
        </div>
      </div>
      <h2>Django MVC Pattern</h2>





<p>Django is a web framework which is also known as a Model-View-Controller (MVC) framework. The most important parts to understand about the MVC pattern are the following.</p>

<h3>Models</h3>


<p>Contain the fields and types of data to be stored in the database.</p>
<p>Django uses Object-Relational Mapping (ORM). This simplifies the interaction to the database. This feature helps avoid writing complex SQL code to create tables in the database.</p>


<div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image3.png"} alt='hj' />
    </div>  



    <h3>Templates</h3>
             <p>Provide an interface between users and websites.
                The templates in Django are HTML templates.</p>
             <p>These templates don’t have to be completely static.
                You can add JavaScript to make the frontend more dynamic, or even use a totally different 
                frontend such as ReactJS.</p> 
                
            <h3  >Views</h3>
              <p>Pass data from database to templates. In templates, data can be arranged and shown to users in a more organized way.
                There are two types of views; function-based views (used in this book) and class-based views.
                </p> 
  
            <h3>URLs</h3>
              <p>To navigate around websites, Django uses URLs just like any other website.
                 When a user clicks on a link to go to a different page,
                 Django receives a URL request and looks in <code className="code-text">urls.py </code>to see which view is tied to the URL being requested.
                
                After Django calls the right view, this view renders the content to a template which is what users see.</p> 

                <h3>Django Admin Site</h3>
          
                <p>Django provides an admin panel which allows us to manage the content of the site.
                   To access the admin panel, we need to create admin credentials first. 
                   Only people that you trust to manage the content of the site should have access.
                </p>

                <p>Create admin credentials by issuing the following command. You can use the credentials below,
                  but feel free to use your own.
             </p>

<pre className="code-box">
<p>python manage.py createsuperuser</p>
<p>Username: admin</p>
<p>Email address: admin@gmail.com</p>
<p>Password: password123 </p>
<p>Password(again): password123</p>
<p>Superuser created successfully</p>
</pre>


</div>
<p>Issue the command <code className="code-text"> python manage.py runserver </code> and go to 
  <a class="text-primary"  href="http://127.0.0.1:8000/admin/" target="_blank"> http://127.0.0.1:8000/admin/ </a> to see the login page for the Django admin site.
</p>


    <p>Enter your credentials and log in. The admin site appears almost empty,
        but you should see two fields which correspond to the <code className="code-text"> AUTHENTICATION AND AUTHORIZATION </code>section provided by Django.
      </p>
      <p>Django provides an authentication system to manage user accounts, groups, permissions,
        and more. 
      </p>
      <p>Later, we will use the Django authentication system to register and create users.</p>
      <p>For now, click on <code className="code-text"> Users </code>to see the superuser created earlier.
      </p>
      <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image5.png"} alt='hj'  />
    </div>  
    


    <p>You should see the username and email address for the superuser.</p>
        <p>To the right, you will see the staff status showing a green check which
          means that this user can access the admin site.
      </p>
        
      <p>The first and last name fields are empty, but you can click on specific users to add information,
         add users to groups, edit fields, and even delete users.
         </p>
         <p>Explore the admin panel 
         before moving to the next section.
      </p>



    <div class="container" >

    <h3>Listings Model</h3>

<p>We already created the listings app and added it to <code className="code-text"> settings.py</code>.
   Now, we will create the listings model.
</p>

<p>Open <code className="code-text"> models.py </code>and start adding the following imports.
</p>

<pre class="code-box">
<p>#listings/models.py </p>
<p>from django.db import models</p>
<p>from django.utils.timezone import now</p>
<p>from datetime import datetime</p></pre>


<h5 class="mb-3"><strong>from django.db import models</strong></h5>
        <p>Allows to create models. Every model will inherit
           from <code className="code-text">django.db.models.Model</code>, in other words, each model is a subclass of this import.
        </p>
        
        <h5 class="mb-3"><strong>from django.utils.timezone import now</strong></h5>
        <p>Gets current time for users based on their specific time zones.
        </p>

        <h5 class="mb-3"><strong>from datetime import datetime</strong></h5>
        <p>Gets date and time with attributes such as year,
           month, day, hour, minute, second.
        </p>

        <p>Add the following code to create the listings model.</p>


        <pre class="code-box">
<p>#listings/models.py</p>

<p>from django.db import models</p>
<p>from django.utils.timezone import now</p> 
<p>from datetime import datetime</p>
<p><br/></p>

<p>class Listings(models.Model):</p>
  <p>&nbsp; class SaleType(models.TextChoices):</p>
   <p>&nbsp; &nbsp; &nbsp;PICK_UP = "Available for pickup"</p>
   <p>&nbsp; &nbsp; &nbsp;SHIP = "Available for shipping"</p>
  

   <p>&nbsp; class ConditionType(models.TextChoices): </p>
   <p>&nbsp; &nbsp; &nbsp;USED = "Used"</p>
   <p>&nbsp; &nbsp; &nbsp;NEW = "New"</p>
  

   <p>&nbsp; class ProductType(models.TextChoices):</p>
   <p>&nbsp; &nbsp; &nbsp;BIKE= "Bike"</p>
   <p>&nbsp; &nbsp; &nbsp;PARTS = "Parts" </p>
   <p>&nbsp; &nbsp; &nbsp;OTHER = "Other"</p>
  

   <p>&nbsp;title = models.CharField(max_length=100)</p>
  
   <p>&nbsp;condition = models.CharField(</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;max_length=50, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;choices=ConditionType.choices,</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;default=ConditionType.USED)</p>

   <p>&nbsp;product_type = models.CharField(</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;max_length=50, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;choices=ProductType.choices, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;default=ProductType.PRINTER)</p>

   <p>&nbsp;sale_type = models.CharField(</p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;max_length=50, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;choices=SaleType.choices, </p>
   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;default=SaleType.SHIP)</p>

   <p>&nbsp;price = models.FloatField()</p>
   <p>&nbsp;city = models.CharField(max_length=100) </p>
   <p>&nbsp;state = models.CharField(max_length=100) </p>
   <p>&nbsp;zipcode = models.CharField(max_length=100) </p>
   <p>&nbsp;main_photo = models.ImageField()</p>
   <p>&nbsp;photo_1 = models.ImageField()</p>
   <p>&nbsp;photo_2 = models.ImageField(blank=True) </p>
   <p>&nbsp;list_date = models.DateTimeField(default=now) </p>
   <p>&nbsp;contact_email = models.CharField(max_length=50)</p>
  
   <p>&nbsp;def __str__(self): </p>
   <p>&nbsp;&nbsp;&nbsp;return self.title</p>
   
   <p>&nbsp;class Meta:</p>
   <p>&nbsp;&nbsp;&nbsp;verbose_name_plural = "Listings"</p>
   
   </pre>

   <p>The listings model has different fields which users
  will be able to enter or select in order to create a listing.</p>
  <p>There are many fields that
  you can add to the listings model, so don’t feel 
  obligated to include all fields listed in the code. Feel free to edit and add other fields as needed.
</p>

<h5 class="mb-3"><strong>CharField</strong></h5>

  <p>Used for small to large-sized strings.
  </p>

<h5 class="mb-3"><strong>ImageField</strong></h5>

  <p>This class has the same attributes and methods as FileField,
    but ImageField also checks if the uploaded file is a valid image. The FileField class can also be used to upload images.
  </p>

<h5 class="mb-3"><strong>DateTimeField</strong></h5>

  <p>Used to display date and time.
  </p>
  

<h5 class="mb-3"><strong>FloatField</strong></h5>
                        
  <p>Used for floating-point numbers.
  </p>

  <h5 class="mb-3"><strong>TextChoices</strong></h5>
                        
  <p>Subclass of the <code className="code-text">Choices </code>class which allows to display string choices.
  </p>

  <h5 class="mb-3"><strong>blank</strong></h5>
                        
  <p>By default, blank is set to <code className="code-text">False </code>which means that the field is mandatory. The field can't be empty otherwise the form will not be submitted.
</p>
   <p> When blank is set to <code className="code-text">True</code>, users can leave the field empty and the form will still be submitted successfully.
    
  </p>

  <h5 class="mb-3"><strong>__str__</strong></h5>
                        
  <p>This method allows to convert an object into a string. This string representation will be shown in the admin panel.
</p>
   <p> If we don't call this method, the admin panel will return the object, but it won't show a familiar name hence difficult to identify.
    
  </p>


  <p>We will use the admin panel to create and manage listings, so we need to register the listings model in <code className="code-text">admin.py</code>.
  </p>
     <p> Open <code className="code-text">admin.py</code> and make the following changes.
    </p>

    <pre className="code-box">
<p>#listings/admin.py </p>
<p>from django.contrib import admin</p>
<p>from .models import Listings</p>
<p><br/></p>
<p>admin.site.register(Listings)</p>

</pre>

<h5 class="mb-3"><strong>from django.contrib import admin</strong></h5>
                        
      <p>Imports the admin module.
      </p>
    
      <h5 class="mb-3"><strong>from .models import model_name</strong></h5>
                            
      <p>Imports the model into <code className="code-text">admin.py</code>, so that we can use the model.
        
      </p>
    
      <h5 class="mb-3"><strong>admin.site.register(model_name)</strong></h5>
                            
      <p>Registers the model in the admin site.
      </p>

      
      <p>After making changes to <code className="code-text">models.py</code>, issue the migration commands below and run the server.</p>


      <pre className="code-box">
<p>python manage.py makemigrations</p>
<p>python manage.py migrate</p>
<p>python manage.py runserver</p>

</pre>

<p>Remember to issue the migration commands every time you make changes to <code className="code-text">models.py</code>.
        </p>

        <p>Access the admin site again. You should see the model <code className="code-text">Listings</code>. 
          
        </p>
  
        <p>Select <code className="code-text">Listings > Add Listings</code>. 
        </p>

        <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image5.5.png"} alt='hj'  />
    </div> 


    <p>I have included a populated form, but you should see an empty form. Go ahead and create some listings.</p>

        
<p>As you start creating listings, you should see them appear on the admin site.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image6.png"} alt='hj'  />
    </div> 

    <div class="footer">
    <p class="left-link"><a class="footer-links"href="/takeoff/chapter1">⬅️ Chapter 1</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter3">Chapter 3 ➡️ </a></p>
</div>

    </div>

</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter2;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter15 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter14">⬅️ Chapter 14</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter16">Chapter 16 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 15: Django Filters</h1>
            </div>
        </div>
      </div>
      <p>In this chapter, we will add a filter to allow users to search for specific listings in the listings page.</p>

<p>Install <code className="code-text"> django-filter</code> by issuing the following command.</p>


              <pre className="code-box">
<p>pip install django-filter</p></pre>

   <p>Go to <code className="code-text">settings.py</code> and add the <code className="code-text">django_filters</code> package to the <code className="code-text">INSTALLED_APPS</code> section.</p>

   <pre className="code-box">
<p>#example/settings.py</p>
<p>...</p>
<p>INSTALLED_APPS = [</p>
<p>   'django.contrib.admin',</p>
<p>   'django.contrib.auth',</p>
<p>   'django.contrib.contenttypes',</p>
<p>   'django.contrib.sessions',</p>
<p>   'django.contrib.messages',</p>
<p>   'django.contrib.staticfiles',</p>
<p>   'listings',</p>
<p>   'users',</p>
<p>   'django_filters'</p>
<p>]</p>
<p>...</p>
</pre>

<p>Create a file called <code className="code-text">filters.py</code> in the listings directory.</p>

<pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/registration</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── filters.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── media</p>
<p>├── users</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>

<p>Open <code className="code-text">filters.py</code> and add the following code.</p>


<pre className="code-box">
<p>#listings/filters.py</p>

<p>import django_filters</p>
<p>from .models import Listings</p>
<p> </p>
<p>class ListingFilter(django_filters.FilterSet):</p>
<p>   class Meta:</p>
<p>       model = Listings</p>
<p>       fields = ['condition', 'city', 'state']</p>
</pre>

<p>We import <code className="code-text">django_filters</code> and the <code className="code-text">Listings</code> model since we will filter through some of the fields in this model.</p>



<p>We display two images for each listing on the listings page.</p>

<p><code className="code-text">django_filters.FilterSet</code> generates a filter for a given model.</p>

<p>The fields chosen are condition, city, and state, but feel free to add more or change them.</p>


    <h3 class="mb-3"><strong>Listings View - Filter</strong></h3>

<p>Open <code className="code-text">views.py</code> and add the filter to the listings view.</p>


<pre className="code-box">
<p>#listings/views.py</p>
<p> </p>
<p>from django.shortcuts import render, redirect</p>
<p>from .forms import ListingForm</p>
<p>from .models import Listings</p>
<p>from .filters import ListingFilter</p>
<p>...</p>
<p>def all_listings(request):</p>
<p>    all_listings = Listings.objects.order_by('-list_date')</p>
<p>    my_Filter = ListingFilter(request.GET, queryset=all_listings)</p>
<p>    all_listings = my_Filter.qs</p>
<p> </p>
<p>    context = &#123;'all_listings': all_listings, 'my_Filter': my_Filter&#125;</p>
<p>    return render(request, 'listings/all_listings.html', context)</p>
<p>...</p>
</pre>



<p>Users make a <code className="code-text">GET</code> request to search for a particular field in the Listings model.</p>

<p>We use <code className="code-text">ListingFilter()</code> to pass the GET request made by users.</p>

<p>The <code className="code-text">queryset</code> variable holds the data that we want to search through.</p>

<p>To access the filtered objects, we use <code className="code-text">.qs</code></p>

<h3 class="mb-3"><strong>Listings Template - Filter</strong></h3>

<p>We need to add the filter to the listings template, so that users can see it.</p>

<p>Open <code className="code-text">all_listings.html</code> and add the following code.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/all_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h1&#62;LISTINGS&#60;/h1&#62;</p>
<p>&#60;div class="form-group"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>      &#60;form method="get"&#62;</p>
<p>        &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; my_Filter.form.condition &#125;&#125;&#60;/p&#62;</p>
<p>        &#60;p&#62;&#60;b&#62;State:&#60;/b&#62;&#123;&#123; my_Filter.form.state &#125;&#125;&#60;/p&#62;</p>
<p>        &#60;p&#62;&#60;b&#62;City:&#60;/b&#62;&#123;&#123; my_Filter.form.city &#125;&#125;&#60;/p&#62;</p>
<p>        &#60;button class="btn" type="submit"&#62;Search&#60;/button&#62;</p>
<p>      &#60;/form&#62;</p>
<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#60;div class="col"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>       &#123;% for listing in all_listings %&#125;</p>
<p>   &#60;img src="&#123;&#123; listing.main_photo.url &#125;&#125;"</p>
<p>        width=300px height=200px alt="connect"/&#62;</p>
<p>   &#60;img src="&#123;&#123; listing.photo_1.url &#125;&#125;"</p>
<p>        width=300px height=200px alt="connect"/&#62;</p>

<p>      &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62;&#123;&#123; listings.title &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; listings.condition &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Sale Type:&#60;/b&#62;&#123;&#123; listings.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Price:&#60;/b&#62;&#123;&#123; listings.price|floatformat:2 &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62;&#123;&#123; listings.city &#125;&#125;,&#123;&#123; listings.state &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62;&#123;&#123; listings.list_date &#125;&#125;&#60;/p&#62;</p>


<p>&#60;ul&#62;</p>
<p>   &#60;a class="btn" href="&#123;% url 'listings:detail' listing.id %&#125;"&#62;View Listing&#60;/a&#62;</p>
<p>&#60;/ul&#62;</p>
<p>&#60;hr&#62;</p>
<p>       &#123;% endfor %&#125;</p>


<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>

<p>The filter acts as a form and that's why we define the <code className="code-text">method</code> as a GET request.</p>

<p>We display the form fields by using <code className="code-text">my_Filter.form.field</code></p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>

<p>Go to the listings page. You should see the search form at the top of the page.</p>


<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image32.png"} alt='hj'  />
    </div>
<p>Before moving on to the next chapter, test the search form and make sure that it works as intended.</p>

<p>This is the last feature for the website before we start styling it.</p>
<div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter14">⬅️ Chapter 14</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter16">Chapter 16 ➡️ </a></p>
</div>
    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter15;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter7 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter6">⬅️ Chapter 6</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter8">Chapter 8 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 7: Detail Listing Page</h1>
            </div>
        </div>
      </div>
      <p>For this chapter, we will work on a page that shows more details about each listing. If users want to know more information about any particular listing, they will access this page.

              </p>

              <h3 class="mb-3"><strong>Detail Listing URL</strong></h3>
              <p>Open<code className="code-text"> urls.py </code>add the path to the detail listing page.
              </p>


          
          
          <p>Parent templates have common elements in them such as navbars, logos, sidebars, etc. We put
common elements in a parent template, so that we don't have rewrite the code in every template.</p>

<p>You don't have to use a parent template, but I recommend creating one to save time.</p>



<p>Open <code className="code-text">base.html</code> and add the following code.</p>

<pre className="code-box">
<p>#listings/urls.py</p>

<p>from django.urls import path</p>
<p>from .import views</p>

<p>app_name = 'listings'</p>
<p>urlpatterns = [</p>
             <p>   path(' ', views.index, name='index'),</p>
             <p>   path('all_listings/', views.all_listings, name='all_listings'),</p>
             <p>   path('new_listing/', views.new_listing, name='new_listing'),</p>
             <p>   path('all_listings/&#60;detail_id&#62;/', views.detail, name='detail'),</p>
             <p>]</p>


</pre>


        <h5><strong>path('all_listings/&#60;detail_id&#62;/', views.detail, name='detail')</strong></h5>


        <p>
The path to the detail listing page is similar to the listings page, but we have included
a <code className="code-text">detail_id</code> which holds the id for each listing. For example, all_listings/1/ corresponds to listing number 1 (detail_id=1) and the same applies to the other listings.</p>


        <h3 class="mb-3"><strong>Detail Listing View</strong></h3>
          
        <p>Open <code className="code-text">views.py</code> and add the following code.
        </p>
        <pre className="code-box">
<p>#listings/views.py</p>

<p>from django.shortcuts import render, redirect</p>
<p>from .forms import ListingForm</p>
<p>from .models import Listings</p>
<p>...</p>
<p>def detail(request, detail_id):</p>
<p>    detail = Listings.objects.get(id=detail_id)</p>
<p>    context = &#123;'detail': detail&#125;</p>
<p>    return render(request, 'listing/detail.html', context)</p>
</pre>
         

<h5><strong>def detail(request, detail_id)</strong></h5>


<p>The parameter <code className="code-text">detail_id</code> has to be added to the detail function to the get individual listings.</p>

<h5><strong>detail = Listings.objects.get(id=detail_id)</strong></h5>


<p>For instance, detail = Listings.objects.get(1) gets all objects for listing 1. Same process for the other listings.</p>


<h5><strong>context = &#123;'detail': detail&#125;</strong></h5>
<h5><strong>return render(request, 'listings/detail.html', context)</strong></h5>


<p>Stored the variable detail in a dictionary and passed it to the detail template.</p>


<h3 class="mb-3"><strong>Detail Listing Template</strong></h3>
          
          <p>Create a template called <code className="code-text">detail.html</code> in the templates folder and add the following code.
          </p>



          <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ ├── index.html</p>
<p>│ │ ├── all_listings.html</p>
<p>│ │ └── new_listing.html</p>
<p>│ │ └── detail.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>



<pre className="code-box">
<p>&#60;--listings/templates/listings/detail.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;div class="main"&#62;</p>
<p>   &#60;h2&#62;LISTING:&#60;/h2&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62;&#123;&#123; detail.title &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; detail.condition &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Product Type:&#60;/b&#62;&#123;&#123; detail.product_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Sale Type&#60;/b&#62;&#123;&#123; detail.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Price&#60;/b&#62;&#123;&#123; detail.price &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62;&#123;&#123; detail.city &#125;&#125;,&#123;&#123; detail.state &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Contact Email:&#60;/b&#62;&#123;&#123; detail.contact_email &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62;&#123;&#123; detail.list_date &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Photos:&#60;/b&#62;&#60;/p&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>

<p>The detail template displays additional information associated with each listing. Photos are not being displayed yet, but we will take care of it in the following chapters.</p>


<h3 class="mb-3"><strong>Listings Template - View Listing</strong></h3>
          
          <p>Before ending this chapter, we will activate the button to access the details of each listing.
          </p>
<p>Open <code className="code-text">all_listings.html</code> and add the following code to activate the button.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/all_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h1&#62;LISTINGS&#60;/h1&#62;</p>
<p>&#60;div class="col"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>       &#123;% for listing in all_listings %&#125;</p>
<p>      &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62;&#123;&#123; listings.title &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; listings.condition &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Sale Type:&#60;/b&#62;&#123;&#123; listings.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Price:&#60;/b&#62;&#123;&#123; listings.price|floatformat:2 &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62;&#123;&#123; listings.city &#125;&#125;,&#123;&#123; listings.state &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62;&#123;&#123; listings.list_date &#125;&#125;&#60;/p&#62;</p>


<p>      &#60;ul&#62;</p>
<p>        &#60;a class="btn" href="&#123;% url 'listings:detail' listing.id %&#125;"&#62;View Listing&#60;/a&#62;</p>
<p>      &#60;/ul&#62;</p>
<p>      &#60;hr&#62;</p>
<p>        &#123;% endfor %&#125;</p>


<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>


<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>

<p>If you click on the 'View Listing' button, you should be taken to the detail page of that listing.
The <code className="code-text">listing.id</code> is used to access the correct listing depending on the id.</p>



   <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image13.png"} alt='hj'  />
    </div>  




  <p>Notice how the URL will change depending on the listing that you select.
            </p>

            <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image14.png"} alt='hj'  />
    </div>  

      
    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter6">⬅️ Chapter 6</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter8">Chapter 8 ➡️ </a></p>
</div>



    </div>



  
   
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter7;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter20 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter19">⬅️ Chapter 19</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter21">Chapter 21 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 20: AWS - Amazon Relational Database Service (RDS)</h1>
            </div>
        </div>
      </div>



      <p>In this chapter, we will move the PostgreSQL database from the local server to a live server provided by Amazon.</p>

<p>Start by opening <code className="code-text">pgAdmin4</code>. We installed it back in chapter 1. If you haven't installed it yet, please do that before continuing.</p>

<p><code className="code-text">pgAdmin4</code> will prompt you for the master password which is the one chosen when installing the program for the first time.</p>


<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image44.png"} alt='hj'  />
    </div>

<p>If you want to open the server, we created in chapter 1, click on PostgreSQL 13 or the equivalent one for you.</p>


<p>You will be prompted to enter the server’s password which is the one set in chapter 1 for the PostgreSQL database called <code className="code-text">example</code>.</p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image45.png"} alt='Django Takeoff Python'  />
    </div>

<p>After entering the password, expand the dropdown menu of the
<code className="code-text"> example </code>
database.</p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image46.png"} alt='Django Takeoff Python'  />
    </div>

<p>We will check some of the information that we have created so far.</p>

<p>Expand the dropdown menu of <code className="code-text">Schemas</code></p>

<p>Click on <code className="code-text">Tables</code> &#62; control-click/right click on <code className="code-text">listings_listings</code> &#62; <code className="code-text">View/Edit Data</code> &#62; <code className="code-text">All Rows</code> </p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image47.png"} alt='Django Takeoff Python'  />
    </div>

<p>On the right side of the screen, you should see all the listings created so far.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image48.png"} alt='Django Takeoff Python'  />
    </div>

<p>Follow the same steps with the <code className="code-text">auth_user</code>  table to see all users who have registered to the website so far.</p>

<p>All the listings and users on the website were created by us to test the website, however, we will not migrate the test data to the live server.</p>
    
    <p>  By moving to a live server, we will start with a clean slate, so no data.
      </p>

      <h3 class="mb-3"><strong>Amazon RDS Setup</strong></h3>



      <p>Amazon Relational Database Service (Amazon RDS) offers a way to create and operate a database in the cloud. 
        It offers different database engines such as PostgreSQL, MySQL, SQL Server, and more.</p>
        
            

            <p>We will only change some sections in the process of creating the database, so if a particular section is not included here,
              it means that section will stay the same, in other words, it will use the default settings.</p>

              <p>To have the website hosted by Amazon RDS, follow the next steps.</p>

              <p>Go to <a class="text-primary" href="https://aws.amazon.com" target="blank">Amazon RDS</a> and create an account.</p>


    
<p>A credit card is required, but it will not be charged because we will be using all free tier services. The card only gets charged once you start going over the limits of the free tier and even then, you will get an email first.</p>


<p>Log in with your credentials.</p>

<p>Search for RDS and select it.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image49.png"} alt='Django Takeoff Python'  />
    </div>


    <p>Click on <code className="code-text">Create database</code></p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image50.png"} alt='Django Takeoff Python'  />
    </div>


    <p>For database creation method, select <code className="code-text">Standard Create</code>.</p>


    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image51.png"} alt='Django Takeoff Python'  />
    </div>

 

<p>For engine options, select <code className="code-text">PostgreSQL</code>.</p>

<p>Leave the PostgreSQL version shown at the moment of creating the database. Most likely that version is allowed in the free tier. </p>
  

  <p>You can select other PostgreSQL versions from the dropdown menu, just make sure the free tier option shows up for the next section (templates).</p>




  <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image53.png"} alt='Django Takeoff Python'  />
    </div>

 

<p>For templates, select <code className="code-text">Free tier</code>.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image52.png"} alt='Django Takeoff Python'  />
    </div>
 

<p>For settings, create a master username and password.</p>
  

 <p>DB instance identifier shows <code className="code-text">database-2</code> because this is my second database. However, it will show <code className="code-text">database-1</code> if this is your first time using Amazon RDS.</p>
  
 <p>My username is <code className="code-text">example</code>, and the password is <code className="code-text">password12345</code>.</p>



 <p>You can choose any username and password just make sure you remember this information.</p>

 <p>We will need these credentials when connecting to Amazon RDS.</p>

 <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image54.png"} alt='Django Takeoff Python'  />
    </div>




<p>For connectivity, select <code className="code-text">Yes</code> for public access.</p>

<p>This allows us to use the database outside of Amazon RDS.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image55.png"} alt='Django Takeoff Python'  />
    </div>

<p>Further down on the same section, expand the dropdown menu for additional configuration and make sure the database port is the correct one, in my case, <code className="code-text">5432</code>. It should be the same for you. </p>

<p>Keep the default values for the rest of settings on this section.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image56.png"} alt='Django Takeoff Python'  />
    </div>

<p>For additional configuration, click on the drop-down menu and create a name for initial database name. I have chosen <code className="code-text">example_1</code> as the name of the database.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image57.png"} alt='Django Takeoff Python'  />
    </div>


<p>Finally, scroll all the way to the bottom of the page. If everything went well, you should see a window with a breakdown of the Amazon RDS free tier resources.</p>

<p>Click on <code className="code-text">Create database</code>.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image58.png"} alt='Django Takeoff Python'  />
    </div>

 
    <h3 class="mb-3"><strong>Connecting to Amazon RDS</strong></h3>

    <p>After creating the database, you will be redirected to a dashboard where you can see all databases.</p>
   
   <p>Wait for the database to be created.
      This could take up to 7 minutes or more.</p>

      <p>After the database is created, click on the database name to see more details of the database.</p>
      
      <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image59.png"} alt='Django Takeoff Python'  />
    </div>
  
  
  <p>Copy the <code className="code-text">Endpoint</code> path. This URL will point Django to where the database is located.</p>
    
    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image60.png"} alt='Django Takeoff Python'  />
    </div>
  
  
  <p>Open <code className="code-text">pgAdmin4</code> and create a server by control-clicking/right clicking on <code className="code-text">Servers</code> > <code className="code-text">Create</code> > <code className="code-text">Server</code>.</p>
    
  
  <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image61.png"} alt='Django Takeoff Python'  />
    </div>
  
  <p>Choose a name for the server. I have chosen <code className="code-text">example_aws</code> for my server.</p>
    
  
    <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image62.png"} alt='Django Takeoff Python'  />
    </div>
  
  
  <p>Click on the <code className="code-text">Connection</code> tab and fill out the fields with the information used when creating the database in Amazon RDS.</p>
    <p>Username > Master username > <code className="code-text">example</code>.</p>
  
      <p>Password > Master password > <code className="code-text">password12345</code>.</p>
    
    <p>Paste the endpoint path in the Host name/address field > <code className="code-text">database-2...amazonaws.com</code>.</p>
      
     <p>Enter the port number in the port field > <code className="code-text">5432</code>.</p> 
      
      <p>Click on <code className="code-text">Save</code>.</p>
     
      <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image63.png"} alt='Django Takeoff Python'  />
    </div>
  
  <p>After clicking on <code className="code-text">Save</code>, the database will be appear on the left side.</p>
    <p>Follow the path <code className="code-text">example_aws</code> > <code className="code-text">Databases</code> > <code className="code-text">example_1</code> > <code className="code-text">Schemas</code> > <code className="code-text">Tables</code> to see what is stored in the database. There should be no data.</p>
    
  
    <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image64.png"} alt='Django Takeoff Python'  />
    </div>
  
  <p>Open <code className="code-text">settings.py</code> and update the code for <code className="code-text">DATABASES</code>.</p>
    

  <pre className="code-box">#example/settings.py
<p>...</p>
<p>DATABASES = &#123;</p>
<p>'default': &#123;</p>
<p>   'ENGINE': 'django.db.backends.postgresql',</p>
<p>   'NAME': 'example_1',</p>
<p>   'USER': 'example',</p> 
<p>   'PASSWORD': 'password12345',</p> 
<p>   'HOST': 'database-2.c9cggbqhqnex.us-east-2.rds.amazonaws.com',</p>
<p>   'PORT': '5432',</p>
<p>   &#125;</p>
<p>&#125;</p>

<p>...   </p>                     
</pre>


<p>Issue the following commands in the terminal window.</p>

<pre className="code-box">
<p>python manage.py migrate</p>
<p>python manage.py runserver</p>
                    
</pre>


<p>Go to the listings page. There should be no listings.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image65.png"} alt='Django Takeoff Python'  />
    </div>

    <p>There is no data because we are connected to the new database.</p>
  
  <p>Create a superuser to access the admin panel and start creating listings again.</p>
  
  <p>We did this back in chapter 1, but we have to do it again because this is a different database.</p>
  
  <p>You can also sign up by using the register form, but eventually you'll need to create a superuser to manage the website.</p>
       

  <pre className="code-box">
<p>python manage.py createsuperuser</p>
<p>*Enter credentials*</p>
<p>python manage.py runserver</p>
                    
</pre>

<p>
  Go to the  
   admin panel and create a listing.
</p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image66.png"} alt='Django Takeoff Python'  />
    </div>

<p>Go back to the listings page to see the listing.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image67.png"} alt='Django Takeoff Python'  />
    </div>

    <p>
  Lastly, go to <code className="code-text">pgAdmin4</code>.</p>
  <p>Follow the path <code className="code-text">example_aws</code> > <code className="code-text">Databases</code> > <code className="code-text">example_1</code> > <code className="code-text">Schemas</code> > <code className="code-text">Tables</code>.</p>
  
  <p>There should be data this time.</p>

  <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image68.png"} alt='Django Takeoff Python'  />
    </div>
    <p>Click on <code className="code-text">Tables</code> > control-click/right click on <code className="code-text">listings_listings</code> > <code className="code-text">View/Edit Data</code> > <code className="code-text">All Rows</code>.</p>

    <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image69.png"} alt='Django Takeoff Python'  />
    </div>

    <p>You should see all the fields of the new listing that was created. This means that the website has been connected to Amazon RDS successfully.</p>
<p>If you encountered any problems, troubleshoot accordingly by going over the steps.</p>


<div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter19">⬅️ Chapter 19</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter21">Chapter 21 ➡️ </a></p>
</div>


    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter20;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter5 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter4">⬅️ Chapter 4</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter6">Chapter 6 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 5 : New Listing Page</h1>
            </div>
        </div>
      </div>
      <p>The listings are being displayed on the listings page. However, the only way to create listings is through
                  the admin panel, so the next step is to create a form for users to enter new listings directly on the website.
              </p>
              <p>The creation process will be URL >> Form >> View >> Template.
              </p>

        

             <h3 class="mb-3"><strong>New Listing URL</strong></h3>

             <p>Open <code className="code-text">urls.py</code> and add the path to the new listing page.</p>

             

             <pre className="code-box">
             
           
             <p>#listings/urls.py </p>
             <p>from django.urls import path</p>
             <p>from .import views</p>
             <p> </p>
                                           
             <p>app_name = 'listings' </p>
             <p>urlpatterns = [</p>
             <p>   path(' ', views.index, name='index'),</p>
             <p>   path('all_listings/', views.all_listings, name='all_listings'),</p>
             <p>   path('new_listing/', views.new_listing, name='new_listing'),</p>
             <p>]</p>

 </pre>

 <h3 class="mb-3"><strong>New Listing Form</strong></h3>
          
          <p>Now, we'll tackle the creation of the new listing form. Create a file named <code className="code-text">forms.py</code> in the listings directory.</p>



          <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>


<p>Open <code className="code-text">forms.py</code> and add the following code.</p>


<pre className="code-box">
             
           
             <p>#listings/forms.py </p>
             <p>from django import forms</p>
             <p>from .models import Listings</p>
             <p> </p>
                                           
             <p>class ListingForm(forms.ModelForm):</p>
             <p>  class Meta:</p>
             <p>      model = Listings</p>
             <p>      fields = ['title', 'condition', 'product_type',</p>
             <p>               'sale_type', 'price', 'main_photo', </p>
             <p>               'photo_1', 'photo_2', 'city', </p>
             <p>               'state', 'zipcode', 'contact_email' </p>
             <p>               ]</p>

 </pre>

 <h5 class="mb-3"><strong>from django import forms</strong></h5>
        <p>This module allows the creation of forms by using the <code className="code-text">ModelForm</code> class.</p>

        <h5 class="mb-3"><strong>from .models import Listings</strong></h5>
        <p>We need the data from the <code className="code-text">Listings</code> model to create the form, so we import it.
</p>

            <h5 class="mb-3"><strong>class Meta</strong></h5>
             <p>Configures the form in relation to the Listings model.
                </p>


        <h3 class="mb-3"><strong>New Listing View</strong></h3>
          
        <p>Open <code className="code-text">views.py</code> and add the following code.
        </p>

         
        <pre className="code-box">
             
           
             <p>#listings/views.py </p>
             <p>from django.shortcuts import render, redirect</p>
             <p>from .forms import ListingForm</p>
             <p>from .models import Listings</p>
             <p> </p>

             <p>...</p>                              
             <p>def new_listing(request):</p>
             <p>    if request.method != 'POST': </p>
             <p>        form = ListingForm()</p>
             <p>    else:</p>
             <p>        form = ListingForm(request.POST, request.FILES)</p>
             <p>        if form.is_valid():</p>
             <p>            form.save()</p>
             <p>            return redirect('listings:all_listings')</p>
             <p> </p>
             <p>    context = &#123;'form': form&#125;</p>
             <p>    return render(request, 'listings/new_listing.html', context)</p>

 </pre>


 <p>In summary, we have defined a function named <code className="code-text">new_listing</code> and use if 
            and else statements to render a form to allow users to submit data, however, let's take a closer look at the code.</p>
        

          <h5><strong>if request.method != 'POST':</strong></h5>
          <h5 class="mb-3"><strong>&nbsp;&nbsp;form = form_name()</strong></h5>


         <p>The reasoning behind using <code className="code-text">!= 'POST'</code> is that in order to render an empty form,
            users will always request data from the server first and that is a GET request.</p>
            

        <h5><strong>else:</strong></h5>
        <h5><strong>&nbsp;&nbsp;form = ListingForm(request.POST, request.FILES)</strong></h5>
        <h5><strong>&nbsp;&nbsp;if form.is_valid():</strong></h5>
        <h5><strong>&nbsp;&nbsp;&nbsp;&nbsp;form.save()</strong></h5>
        <h5 class="mb-3"><strong>&nbsp;&nbsp;&nbsp;&nbsp;return redirect('listings:all_listings')</strong></h5>
  
        <p>Users see an empty form. Once they fill it out,
            and submit it, the code above will run. </p>
              

        <h5 class="mb-3"><strong>form = ListingForm(request.POST, request.FILES)</strong></h5>
        <p>The variable <code className="code-text">form</code> holds the data being submitted by the user, <code className="code-text">request.POST</code> takes
        care of data and <code className="code-text">request.FILES</code> takes care of files being submitted, in this case, image files.</p>

        <h5 class="mb-3"><strong>if form.is_valid():</strong></h5>
        <p>Checks if the information in the form is valid by using the <code className="code-text">is_valid()</code> method.
        This method checks that all required fields are filled out, max length for the fields,
         data matching field types, and more.</p>

        <h5 class="mb-3"><strong>form.save()</strong></h5>
        <p>If the form is valid, use <code className="code-text">save()</code> to write the data from the form to the database.</p>

        <h5 class="mb-3"><strong>return redirect('listings:all_listings')</strong></h5>
        <p>After all information in the form has been checked and submitted, proceed to redirect
             users to the listings page. You can redirect users to a different page if you prefer.</p>

        <h5><strong>context = &#123;'form': form&#125;</strong></h5>
        <h5 class="mb-3"><strong>return render(request, 'listings/new_listing.html', context)</strong></h5>
        <p>Stores the <code className="code-text">form</code> variable in a dictionary and passes it to the <code className="code-text">new_listing</code> template.</p>

        <h3 class="mb-3"><strong>New Listing Template</strong></h3>
        <p>The final step is to render the form in a template. Create a template called <code className="code-text">new_listing.html</code> in the templates folder.</p>




        <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ ├── index.html</p>
<p>│ │ ├── all_listings.html</p>
<p>│ │ └── new_listing.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>

<p>Open <code className="code-text">new_listing.html</code> and add the following code.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/new_listing.html--&#62;</p>
<p> </p>
<p>&#60;h1&#62;NEW LISTING&#60;/h1&#62;</p>

<p> </p>
<p>&#60;form action="&#123;% url 'listings:new_listing' %&#125;"</p>
<p>      method="post"</p>
<p>      enctype="multipart/form-data"&#62;</p>
<p> </p>
<p>&#60;div class="form-group"&#62;</p>
<p>    &#123;% csrf_token %&#125;</p>
<p>    &#123;&#123; forms.as_p &#125;&#125;</p>
<p>&#60;/div&#62;</p>
<p> </p>
<p> &#60;button type="submit" class="btn"&#62;Create Listing</p>
<p> &#60;/button&#62;</p>

<p>&#60;/form&#62;</p>

</pre>


<p>The <code className="code-text">action</code> argument is sending data to the URL of the <code className="code-text">new_listing</code> view function.
               This view is where the data would be processed.</p>
               
            <p>The <code className="code-text">method</code> argument defines what 
                kind of request is being submitted, in this case, a POST request.</p>
            <p>Also include <code className="code-text">enctype="multipart/form-data"</code> to send image files. If we don't include this,
                 <code className="code-text">request.FILES</code> will be empty. When submitting only data, there is no need to include <code className="code-text">enctype</code>.</p> 
        

          <h5 class="mb-3"><strong>&#123;% csrf_token %&#125;</strong></h5>
          
          <p>The Cross Site Request Forgeries (csrf) token is a security measure which needs to be included for any POST form.
          </p>

          <h5 class="mb-3"><strong>&#123;&#123; form.as_p &#125;&#125;</strong></h5>
          
          <p>The built-in template tag &#123;&#123; form &#125;&#125; already takes care of displaying the labels and input fields of the form.
               We added <code className="code-text">as_p</code> to display the form fields wrapped in paragraph tags.
          </p>

          <p>Issue <code className="code-text">python manage.py runserver</code> and go to <a class="text-primary" href="http://127.0.0.1:8000/new_listing/" target="_blank">http://127.0.0.1:8000/new_listing/</a>
          </p>

       <p>If you don't see the form, go back through the chapter and fix any problems you might have.</p>
       <p>Try out the form by creating a few more listings.
        After you submit the form, you should be redirected to the listings page where you can see the new listings added.
   </p>

   <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image9.png"} alt='hj'  />
    </div>  



    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter4">⬅️ Chapter 4</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter6">Chapter 6 ➡️ </a></p>
</div>




    </div>



  
   
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter5;

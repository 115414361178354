import React from "react";
import Jobs from "./pages/jobs"

import {Router, Routes, Route} from "react-router-dom"
import Navbar from "./components/Navbar";
import Chapter1 from "./pages/TakeoffPages/chapter1";
import Chapter2 from "./pages/TakeoffPages/chapter2";
import Chapter3 from "./pages/TakeoffPages/chapter3";
import Chapter4 from "./pages/TakeoffPages/chapter4";
import Chapter5 from "./pages/TakeoffPages/chapter5";
import Chapter6 from "./pages/TakeoffPages/chapter6";
import Chapter7 from "./pages/TakeoffPages/chapter7";
import Chapter8 from "./pages/TakeoffPages/chapter8";
import Chapter9 from "./pages/TakeoffPages/chapter9";
import Chapter10 from "./pages/TakeoffPages/chapter10";
import Chapter11 from "./pages/TakeoffPages/chapter11";
import Chapter12 from "./pages/TakeoffPages/chapter12";
import Chapter13 from "./pages/TakeoffPages/chapter13";
import Chapter14 from "./pages/TakeoffPages/chapter14";
import Chapter15 from "./pages/TakeoffPages/chapter15";
import Chapter16 from "./pages/TakeoffPages/chapter16";
import Chapter17 from "./pages/TakeoffPages/chapter17";
import Chapter18 from "./pages/TakeoffPages/chapter18";
import Chapter19 from "./pages/TakeoffPages/chapter19";
import Chapter20 from "./pages/TakeoffPages/chapter20";
import Chapter21 from "./pages/TakeoffPages/chapter21";
import Chapter22 from "./pages/TakeoffPages/chapter22";
import Takeoff from "./pages/takeoff";


export default () => {
  return (
    <div className="App">
  
      
      <Routes>
        <Route exact path='/' element={<Jobs/>} />
        <Route exact path='/takeoff' element={<Takeoff/>} />
        <Route exact path='/takeoff/chapter1' element={<Chapter1/>} />
        <Route exact path='/takeoff/chapter2' element={<Chapter2/>} />
        <Route exact path='/takeoff/chapter3' element={<Chapter3/>} />
        <Route exact path='/takeoff/chapter4' element={<Chapter4/>} />
        <Route exact path='/takeoff/chapter5' element={<Chapter5/>} />
        <Route exact path='/takeoff/chapter6' element={<Chapter6/>} />
        <Route exact path='/takeoff/chapter7' element={<Chapter7/>} />
        <Route exact path='/takeoff/chapter8' element={<Chapter8/>} />
        <Route exact path='/takeoff/chapter9' element={<Chapter9/>} />
        <Route exact path='/takeoff/chapter10' element={<Chapter10/>} />
        <Route exact path='/takeoff/chapter11' element={<Chapter11/>} />
        <Route exact path='/takeoff/chapter12' element={<Chapter12/>} />
        <Route exact path='/takeoff/chapter13' element={<Chapter13/>} />
        <Route exact path='/takeoff/chapter14' element={<Chapter14/>} />
        <Route exact path='/takeoff/chapter15' element={<Chapter15/>} />
        <Route exact path='/takeoff/chapter16' element={<Chapter16/>} />
        <Route exact path='/takeoff/chapter17' element={<Chapter17/>} />
        <Route exact path='/takeoff/chapter18' element={<Chapter18/>} />
        <Route exact path='/takeoff/chapter19' element={<Chapter19/>} />
        <Route exact path='/takeoff/chapter20' element={<Chapter20/>} />
        <Route exact path='/takeoff/chapter21' element={<Chapter21/>} />
        <Route exact path='/takeoff/chapter22' element={<Chapter22/>} />
      </Routes>
      
    </div>
  );
};

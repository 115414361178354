import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter14 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter13">⬅️ Chapter 13</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter15">Chapter 15 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 14: Images</h1>
            </div>
        </div>
      </div>
      <p>In this chapter, we will work on displaying images on the website, so that users can see the images of the listings.</p>

<p>Open <code className="code-text"> settings.py </code> and check that the code below is present in the file.</p>

<p>The paths for <code className="code-text"> MEDIA_URL </code> and <code className="code-text"> MEDIA_ROOT </code> were set up back in chapter 1.</p>



              <pre className="code-box">
<p>#example/settings.py</p>
<p>...</p>
<p>DEBUG = True</p>
<p>...</p>
<p>STATIC_URL = ‘/static/’</p>
<p>STATICFILES_DIRS = [os.path.join(BASE_DIR, 'static')]</p>
<p>MEDIA_URL = '/media/'</p>
<p>MEDIA_ROOT = os.path.join(BASE_DIR, 'media')</p>
<p>FILE_UPLOAD_PERMISSIONS = 0O640</p>
<p>...</p>
   </pre>
   <p>Open <code className="code-text">urls.py</code> from the project folder and add the following code.</p>

   <pre className="code-box">
<p>#example/urls.py</p>
<p>from django.contrib import admin</p>
<p>from django.urls import path, include</p>
<p>from django.conf import settings</p>
<p>from django.conf.urls.static import static</p>

<p> </p>
<p>urlpatterns = [</p>
             <p>   path('admin/', admin.site.urls),</p>
             <p>   path(' ', include('listings.urls')),</p>
             <p>   path('users/', include('users.urls')),</p>
             <p>]</p>
<p>if settings.DEBUG:</p>
<p>  urlpatterns += static(settings.MEDIA_URL, document_root=settings.MEDIA_ROOT)</p>
</pre>

<p>The if statement sets up the path to serve media files for the website.</p>

<p>While <code className="code-text">DEBUG = True</code>, the path above will be the path used to serve images.</p>

<p>During production it is not recommended to serve static or media files from the local server, but for now images will be served locally.</p>

<h3 class="mb-3"><strong>Listings Template - Images</strong></h3>


<p>Open <code className="code-text">all_listings.html</code> and add the following code to display images for the listings.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/all_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h1&#62;LISTINGS&#60;/h1&#62;</p>
<p>&#60;div class="col"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>       &#123;% for listing in all_listings %&#125;</p>
<p>   &#60;img src="&#123;&#123; listing.main_photo.url &#125;&#125;"</p>
<p>        width=300px height=200px alt="connect"/&#62;</p>
<p>   &#60;img src="&#123;&#123; listing.photo_1.url &#125;&#125;"</p>
<p>        width=300px height=200px alt="connect"/&#62;</p>
<p> </p>
<p>      &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62;&#123;&#123; listings.title &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; listings.condition &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Sale Type:&#60;/b&#62;&#123;&#123; listings.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Price:&#60;/b&#62;&#123;&#123; listings.price|floatformat:2 &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62;&#123;&#123; listings.city &#125;&#125;,&#123;&#123; listings.state &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62;&#123;&#123; listings.list_date &#125;&#125;&#60;/p&#62;</p>


<p>&#60;ul&#62;</p>
<p>   &#60;a class="btn" href="&#123;% url 'listings:detail' listing.id %&#125;"&#62;View Listing&#60;/a&#62;</p>
<p>&#60;/ul&#62;</p>
<p>&#60;hr&#62;</p>
<p>       &#123;% endfor %&#125;</p>
<p> </p>

<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>





<p>We display two images for each listing on the listings page.</p>

<p><code className="code-text">.url</code> provides Django with the path to where the image is located.</p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>

<p>Go to the listings page to see the images being displayed for each listing.</p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image30.png"} alt='hj'  />
    </div> 


    <h3 class="mb-3"><strong>Detail Listing Template - Images</strong></h3>

<p>We also need to show images on the detail listing page.</p>

<p>Open <code className="code-text">detail.html</code> and add the following code.</p>

<pre className="code-box">
<p>&#60;--listings/templates/listings/detail.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p> </p>
<p>&#60;div class="main"&#62;</p>
<p>   &#60;h2&#62;LISTING:&#60;/h2&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62;&#123;&#123; detail.title &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; detail.condition &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Product Type:&#60;/b&#62;&#123;&#123; detail.product_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Sale Type&#60;/b&#62;&#123;&#123; detail.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Price&#60;/b&#62;&#123;&#123; detail.price &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62;&#123;&#123; detail.city &#125;&#125;,&#123;&#123; detail.state &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Contact Email:&#60;/b&#62;&#123;&#123; detail.contact_email &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62;&#123;&#123; detail.list_date &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Photos:&#60;/b&#62;&#60;/p&#62;</p>
<p>      &#60;img src="&#123;&#123; detail.main_photo.url &#125;&#125;" width=400px height=400px/&#62;</p>
<p>      &#60;img src="&#123;&#123; detail.photo_1.url &#125;&#125;" width=400px height=400px/&#62;</p>
<p>      &#123;% if detail.photo_2 %&#125;</p>
<p>      &#60;img src="&#123;&#123; detail.photo_2.url &#125;&#125;" width=400px height=400px/&#62;</p>
<p>      &#123;% endif %&#125;</p>
<p>&#60;/div&#62;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>


<p>Users can submit up to three images. Out of the three images, the first two are mandatory and the third is optional. We use the if statement to display the third one only if it exists.</p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>

<p>Visit the detail listing page for any of the listings to see the images being displayed.</p>


<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image31.png"} alt='hj'  />
    </div>
    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter13">⬅️ Chapter 13</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter15">Chapter 15 ➡️ </a></p>
</div>

    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter14;

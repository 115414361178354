import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter3 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter2">⬅️ Chapter 2</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter4">Chapter 4 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 3 : Homepage</h1>
            </div>
        </div>
      </div>
      <p>Let’s create a homepage for the site, so that users can land there.
                 From the homepage, they can navigate to other pages and vice versa.
              </p>
              <p>Open the <code className="code-text">urls.py </code>file of the main project.
              </p>

              <pre className="code-box">
              <p>#example/urls.py </p>
<p>from django.contrib import admin</p>
<p>from django.urls import path</p>
<p> </p>
<p>urlpatterns = [</p>
<p>  path('admin/', admin.site.urls),</p>
<p> ] </p>
</pre>

<p>We need to include the path to the URLs of the listings app in the main
<code className="code-text"> urls.py </code>file. 
              </p>


              <pre className="code-box">
<p>#example/urls.py </p>
<p>from django.contrib import admin</p>
<p>from django.urls import path, include</p>
<p> </p>
<p>urlpatterns = [</p>
<p>  path('admin/', admin.site.urls),</p>
<p>  path(' ', include('listings.urls')),</p>
<p> ] </p>
</pre>


<h5  class="mb-3"><strong>from django.urls import path</strong></h5>
             <p>This module helps Django look for the variable <code className="code-text"> urlpatterns </code>which points to different paths in the website.</p>
            
                
            <h5  class="mb-3"><strong>from django.urls import include</strong></h5>
              <p>Django uses this module to include URLs from different apps inside the project.
                </p> 
                <p>Let's create a second <code className="code-text"> urls.py </code>file, but this one will be in the listings directory.
                </p> 

                <p>The listings directory should have the following files.
                </p> 


                <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>

<h3  class="mb-3"><strong>Homepage URL</strong></h3>
              <p>Open the newly created <code className="code-text"> urls.py </code>file and add the following code.</p> 



              <pre className="code-box">
<p>#listings/urls.py </p>
<p>from django.urls import path</p>
<p>from .import views</p>
<p> </p>
<p>app_name = 'listings'</p>
<p></p>
<p>urlpatterns = [</p>
<p>   path('', views.index, name='index'),</p>
<p> ] </p>
</pre>

<h5 class="mb-3"><strong>from .import views</strong></h5>
          
          <p>This module imports all views from the <code className="code-text">views.py </code> file in the listings directory.</p>
          
          <p>
             Django maps the URLs to the views and calls the functions in <code className="code-text">views.py </code>.
          </p>

          <p>In this case, the empty string (‘ ’) matches the base URL for the project.
             This is going to be the homepage for the site. 
       </p>
       <p>For <code className="code-text">views.index </code>, Django will call the index view. Also, <code className="code-text">name= ‘index’</code> is
          just a way to reference the URL pattern without having to write the entire URL.
  </p>
  <h5  class="mb-3"><strong>app_name</strong></h5>
    
  <p> Helps Django identify and choose the correct <code className="code-text">urls.py </code>
      file from other <code className="code-text">urls.py </code> files in the project.
  </p>

  <h3  class="mb-3"><strong>Homepage View</strong></h3>
    
  <p>A view for the homepage also needs to be created.
  </p>

  <p>Open the <code className="code-text">views.py </code> file in the listings directory.
  </p>


  <pre className="code-box">
<p>#listings/views.py </p>
<p>from django.shortcuts import render</p>
<p> </p>
<p>def index(request):</p>
<p>   return render(request, 'listings/index.html')</p>
</pre>


<h5 class="mb-3"><strong>from django.shortcuts import render</strong></h5>
          
          <p>Calls the helper function <code className="code-text">render</code> which
             generates a response that can be rendered in a template.
          </p>

          <h3 class="mb-3"><strong>Homepage Template</strong></h3>
          
          <p>Finally, we need templates
             so that users can see the data being displayed. In Django, templates are HTML files 
             which show data provided by views.
          </p>

          <p>Create a folder called <code className="code-text">templates</code> inside the listings directory.</p>

          <p>Inside the templates folder, create a folder with the name of the app, in this case, <code className="code-text">listings</code>.</p>
              
          <p>
             Finally, inside the last folder created (listings), create a file named <code className="code-text">index.html</code>. 
         </p>

         <p>This helps Django interpret and find templates for the correct app without any ambiguity.</p>
            
         <p>See the project tree below for new folders and files created.
       </p>


       <pre className="code-box">
<p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ └── index.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>

<p>Open <code className="code-text">index.html </code> and add the following code.
      </p>


      <pre className="code-box">
<p>&#60;--listings/templates/listings/index.html--&#62;</p>
<p> </p>
<p>&#60;h3&#62;Bike Find3r&#60;/h3&#62;</p>
<p>&#60;p&#62;This is a homepage&#60;/p&#62;</p>
</pre>



      <p>For this particular template, there’s not much going on. We just added a header
         and a paragraph. 
      </p>
      <p>We will style the website later, so for now, we’ll keep the templates simple. 
      </p>
      <p>Usually, the rule of thumb is to try to get the functionality of the website working, and then style it.
         However, you can choose to style as you go along which is also valid. </p>
      <p>Save changes and run <code className="code-text">python manage.py runserver</code>.</p>
        
        
       <p>Go to <a class="text-primary"  href="http://127.0.0.1:8000/" target="_blank">http://127.0.0.1:8000/</a> to see the homepage of the site.
      </p>




<div class="text-center image-div" >
    <img className="image-post=chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image7.png"} alt='hj'  />
    </div>  

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter2">⬅️ Chapter 2</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter4">Chapter 4 ➡️ </a></p>
</div>

</div>





</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter3;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter9 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter8">⬅️ Chapter 8</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter10">Chapter 10 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 9: Edit Listing Page</h1>
            </div>
        </div>
      </div>
      <p>Up to this point, we can create new listings, see more details about any listings, but we can't edit or delete listings. In this chapter, we will take care of adding the editing feature.</p>

              <h3 class="mb-3"><strong>Edit Listing URL</strong></h3>
              <p>Open<code className="code-text"> urls.py </code>add the path to the edit listing page.
              </p>


<pre className="code-box">
<p>#listings/urls.py</p>

<p>from django.urls import path</p>
<p>from .import views</p>

<p>app_name = 'listings'</p>
<p>urlpatterns = [</p>
             <p>   path(' ', views.index, name='index'),</p>
             <p>   path('all_listings/', views.all_listings, name='all_listings'),</p>
             <p>   path('new_listing/', views.new_listing, name='new_listing'),</p>
             <p>   path('all_listings/&#60;detail_id&#62;/', views.detail, name='detail'),</p>
             <p>   path('my_listings/', views.my_listings, name='my_listings'),</p>
             <p>   path('edit_listing/&#60;edit_id&#62;/', views.edit_listing, name='edit_listing'),</p>
             <p>]</p>


</pre>

<p>Notice how we have included <code className="code-text"> edit_id </code> in the URL, so that we edit the right listing. We did something similar for the detail listing URL.</p>

<h3 class="mb-3"><strong>Edit Listing View</strong></h3>
<p>We will be dealing with editing existing information in a form, so the edit listing view will be different than what we are used to seeing.</p>
              <p>Open<code className="code-text"> views.py </code> and create the view for the edit listing page.
              </p>


              <pre className="code-box">
<p>#listings/views.py</p>

<p>from django.shortcuts import render, redirect</p>
<p>from .forms import ListingForm</p>
<p>from .models import Listings</p>
<p>...</p>
<p>def edit_listing(request, edit_id):</p>
<p>    listing = Listings.objects.get(id=edit_id)</p>
<p> </p>
<p>    if request.method != 'POST':</p>
<p>        form = ListingForm(instance=listing)</p>
<p>    else:</p>
<p>        form = ListingForm(request.POST, request.FILES, instance=listing)</p>
<p>        if form.is_valid():</p>
<p>           form.save()</p>
<p>           return redirect('listings:my_listings')</p>
<p> </p>
<p>    context = &#123;'listing': listing, 'form': form&#125;</p>
<p>    return render(request, 'listings/edit_listing.html', context)</p>
</pre>
         

<p>The main difference between the new listing view and the edit listing view is that we are not creating a listing but editing an existing one.</p>

<h5 class="mb-3"><strong>listing = Listings.objects.get(id=edit_id)</strong></h5>

<p>Gets the specific listing we are trying to edit. After this is done, the process is very similar to the new listing view.</p>

<h5 class="mb-3"><strong>form = ListingForm(instance=listing)</strong></h5>

<p>If the request <code className="code-text">!=</code> POST, we generate a form, but this time the fields will be already filled in with the existing information for that particular listing.</p>

<p>This is achieved by setting <code className="code-text">instance=listing</code> which gets a particular instance from the database into the form.</p>

<h5 class="mb-3"><strong>form = ListingForm(request.POST, request.FILES, instance=listing)</strong></h5>

<p>To edit an existent model instance in the database, we include <code className="code-text">instance=listing</code> so that we replace that particular instance in the database when submitting the form again.</p>




<h3 class="mb-3"><strong>Edit Listing Template</strong></h3>
              <p>Create a template called<code className="code-text"> edit_listing.html </code> in the templates folder and add the following code.
              </p>



              <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ ├── index.html</p>
<p>│ │ ├── all_listings.html</p>
<p>│ │ ├── new_listing.html</p>
<p>│ │ ├── detail.html</p>
<p>│ │ ├── my_listings.html</p>
<p>│ │ └── edit_listing.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>



<pre className="code-box">
<p>&#60;--listings/templates/listings/edit_listing.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h2&#62;EDITING:&#60;/h2&#62;</p>

<p>&#60;form action="&#123;% url 'listings:edit_listing' listing.id %&#125;"</p>
<p>       method='post' enctype='multipart/form-data'&#62;</p>
<p>       &#60;div class='form-group'&#62;</p>
<p>         &#123;% csrf_token %&#125;</p>
<p>         &#123;&#123; form.as_p &#125;&#125;</p>
<p>       &#60;/div&#62;</p>
<p>       &#60;button type='submit' class='btn'&#62;Edit Listing</p>
<p>       &#60;/button&#62;</p>
<p>&#60;/form&#62;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>


<p>For the code above, the   method refers to the edit listing page. Also, we have included listing.id which is needed to edit the correct listing when submitting the form.</p>

<h3 class="mb-3"><strong>My Listings - Edit</strong></h3>
          
<p>Open <code className="code-text">my_listings.html</code> and activate the edit button</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/my_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h2&#62;MY LISTINGS:&#60;/h2&#62;</p>

<p>&#60;div class="row"&#62;</p>
<p>  &#60;div class="col"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>     &#60;table class="table"&#62;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;th&#62;Product&#60;/th&#62;</p>
<p>         &#60;th&#62;Date Listed&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>       &#60;tr&#62;</p>
<p>       &#123;% for my_listing in my_listings %&#125;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.title &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.list_date &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn"</p>
<p>           href="&#123;% url 'listings:detail' my_listing.id %&#125;"&#62;View</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn"</p>
<p>           href="&#123;% url 'listings:edit_listing' my_listing.id %&#125;"&#62;Edit</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn" href=""&#62;Delete&#60;/a&#62;&#60;/td&#62;</p>
<p>       &#60;/tr&#62;</p>
<p>       &#123;% endfor %&#125;</p>
<p>      &#60;/table&#62;</p>
<p>       &#60;div class="container"&#62;</p>
<p>         &#60;a class="btn"&#62;</p>
<p>           href="&#123;% url 'listings:new_listing' %&#125;"&#62;New Listing</p>
<p>         &#60;/a&#62;</p>
<p>       &#60;/div&#62;</p>
<p>     &#60;/div&#62;</p>
<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>


<p>Activated the edit button by adding the path to the edit page and passed  <code className="code-text">my_listing.id </code> to edit the right listing.</p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver </code> command.</p>

<p>Go to my listings page, and edit some listings to test the form.</p>

<p>After you edit and submit the form, the listings should reflect the changes made.</p>

   <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image16.png"} alt='hj'  />
    </div>  

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter8">⬅️ Chapter 8</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter10">Chapter 10 ➡️ </a></p>
</div>

    </div>



  
   
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter9;

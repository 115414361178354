import React, { useEffect, useState } from "react";
import { Box, Card } from "@material-ui/core";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../theme/theme"
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Content from "../components/Content";




const Home = () => {


  return (

    
  <ThemeProvider theme={theme}>
    <Navbar />
    
    <Header/>
    <Subheader/>
    <Content/>

  </ThemeProvider>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter17 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter16">⬅️ Chapter 16</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter18">Chapter 18 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 17: Styling with Bootstrap (Part II)</h1>
            </div>
        </div>
      </div>
    

          <h3 class="mb-3"><strong>Listings Page - Bootstrap</strong></h3>

   <p>Open <code className="code-text">all_listings.html</code> and edit the code as shown.</p>

   <pre className="code-box"><p>&#60;!--listings/templates/listings/all_listings.html--&#62;</p>
   <p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
  <p>  &#60;div class="container text-center p-3 " style="width: 500px;"&#62;</p>
    <p>    &#60;h1 class="text-dark"&#62;Listings&#60;/h1&#62;</p>
    <p>    &#60;div class="form-group"&#62;</p>
      <p>      &#60;div class="card card-body bg-light text-dark rounded"&#62;</p>
        <p>        &#60;form method="get"&#62;</p>
          <p>          &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; my_Filter.form.condition &#125;&#125;&#60;/p&#62;</p>
          <p>          &#60;p&#62;&#60;b&#62;State:&#60;/b&#62;&#123;&#123; my_Filter.form.state &#125;&#125;&#60;/p&#62;</p>
          <p>          &#60;p&#62;&#60;b&#62;City:&#60;/b&#62;&#123;&#123; my_Filter.form.city &#125;&#125;&#60;/p&#62;</p>
          <p>          &#60;button class="btn btn-warning border-dark" type="submit"&#62;Search</p>
          <p>          &#60;/button&#62;</p>
        <p>         &#60;/form&#62;</p>
      <p>      &#60;/div&#62;</p>
    <p>    &#60;/div&#62;</p>
  <p>  &#60;/div&#62;</p> 
  <p>&#60;div class="container"&#62;</p>
  <p>  &#60;div class="card card-body bg-light text-dark text-center p-3 "&#62;</p>                 
    <p>    &#123;% for listing in all_listings %&#125;</p>                  
      <p>    &#60;p&#62;</p>
        <p>    &#60;img class="card-img-right img-thumbnail"  src="&#123;&#123; listing.main_photo.url &#125;&#125;"</p> 
        <p>     width=300px height=200px style="max-height:200px" alt="connect"&#62;</p>
        <p>    &#60;img class="card-img-right img-thumbnail" src="&#123;&#123; listing.photo_1.url &#125;&#125;"</p> 
        <p>     width=300px height=200px style="max-height:200px" alt="connect"&#62;</p>
      <p>    &#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62; &#123;&#123; listing.title &#125;&#125;&#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62; &#123;&#123; listing.condition &#125;&#125;&#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Sale Type:&#60;/b&#62; &#123;&#123; listing.sale_type &#125;&#125;&#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Price:&#60;/b&#62; $ &#123;&#123; listing.price|floatformat:2 &#125;&#125;&#60;/p&#62;</p>  
      <p>    &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62; &#123;&#123; listing.city &#125;&#125;, &#123;&#123; listing.state &#125;&#125;&#60;/p&#62;</p>  
      <p>    &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62; &#123;&#123; listing.list_date &#125;&#125;&#60;/p&#62;    </p>           
      <p>    &#60;ul&#62;&#60;a class="btn btn-warning border-dark" </p>  
      <p>          href="&#123;% url 'cards:detail' listing.id %&#125;"&#62;View Listing</p>  
      <p>    &#60;/a&#62;&#60;/ul&#62;</p>  
      <p>    &#60;hr&#62;            </p>       
      <p>    &#123;% endfor %&#125;  </p>                  
      <p>  &#60;/div&#62;</p>  
      <p>&#60;/div&#62;</p>  
                  
      <p>&#123;%endblock content%&#125;</p>          
          </pre>




<p>We have arranged the search filter and listings in two different containers. Text and images are
centered, the background color of the containers has changed, and also the color of the buttons.</p>


   <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image35.png"} alt='hj'  />
    </div>


    <h3 class="mb-3"><strong>Detail Listing Page - Bootstrap</strong></h3>

<p>Open <code className="code-text">detail.html</code> and make the following changes to the template.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/detail.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p> </p>
<p>&#60;div class="container"&#62;</p>
<p>  &#60;div class="row p-5"&#62;</p>
<p>    &#60;div class="col"&#62;</p>
<p>    &#60;h3 class="text-dark mb-4"&#62;LISTING:&#60;/h3&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62;&#123;&#123; detail.title &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; detail.condition &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Product Type:&#60;/b&#62;&#123;&#123; detail.product_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Sale Type&#60;/b&#62;&#123;&#123; detail.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Price&#60;/b&#62;&#123;&#123; detail.price &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62;&#123;&#123; detail.city &#125;&#125;,&#123;&#123; detail.state &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Contact Email:&#60;/b&#62;&#123;&#123; detail.contact_email &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62;&#123;&#123; detail.list_date &#125;&#125;&#60;/p&#62;</p>
<p>    &#60;/div&#62;</p>
<p>    &#60;div class="col-4 mt-4"&#62;</p>
<p>      &#60;img class = "card mb-3 " src="&#123;&#123; detail.main_photo.url &#125;&#125;"</p>
<p>              width= 350px height=300px style= "max-width: 100%; </p>
<p>              max-height: 100%;" /&#62;</p>
<p>      &#123;% if detail.photo_2%&#125;</p>
<p>      &#60;img class = "card " src="&#123;&#123; detail.photo_2.url &#125;&#125;" </p>
<p>              width= 350px height=300px style= "max-width: 100%; </p>
<p>              max-height: 100%;"/&#62;</p>
<p>      &#123;% endif %&#125;</p>
<p>    &#60;/div&#62;</p>
<p>    &#60;div class="col-4 mt-4"&#62;</p>
<p>      &#60;img class = "card " src="&#123;&#123; detail.photo_1.url &#125;&#125;" </p>
<p>              width= 350px height=300px style= "max-width: 100%; </p>
<p>              max-height: 100%;" /&#62;</p>
<p>    &#60;/div&#62;</p> 
<p>  &#60;/div&#62;</p> 
<p>&#60;/div&#62;</p> 
<p>&#123;%endblock content%&#125;</p>
</pre>

<p>For the detail listing page, users will see more information about any listing plus the images being displayed in a decent size.</p>

<p>Save changes and visit the detail listing page.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image36.png"} alt='hj'  />
    </div>

    <h3 class="mb-3"><strong>My Listings Page - Bootstrap</strong></h3>

    <p>We will end this chapter by styling my listings page.</p>

<p>Open <code className="code-text">my_listings.html</code> and edit the code as shown.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/my_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h2 class="text-center mb-3"&#62;MY LISTINGS:&#60;/h2&#62;</p>
<p> </p>
<p>&#60;div class="row"&#62;</p>
<p>  &#60;div class="col"&#62;</p>
<p>   &#60;div class="container text-center p-3"&#62;</p>
<p>     &#60;table class="table text-center"&#62;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;th&#62;Product&#60;/th&#62;</p>
<p>         &#60;th&#62;Date Listed&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>       &#60;tr&#62;</p>
<p>       &#123;% for my_listing in my_listings %&#125;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.title &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.list_date &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn btn-warning"</p>
<p>           href="&#123;% url 'listings:detail' my_listing.id %&#125;"&#62;View</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn btn-warning"</p>
<p>           href="&#123;% url 'listings:edit_listing' my_listing.id %&#125;"&#62;Edit</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn btn-warning"</p>
<p>           href="&#123;% url 'listings:delete_listing' my_listing.id %&#125;"&#62;Delete</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>       &#60;/tr&#62;</p>
<p>       &#123;% endfor %&#125;</p>
<p>      &#60;/table&#62;</p>
<p>       &#60;div class="container text-center"&#62;</p>
<p>         &#60;a class="btn btn-warning"&#62;</p>
<p>           href="&#123;% url 'listings:new_listing' %&#125;"&#62;New Listing</p>
<p>         &#60;/a&#62;</p>
<p>       &#60;/div&#62;</p>
<p>     &#60;/div&#62;</p>
<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>

<p>There are not significant changes made to this page. We just added some styling to make it look better.</p>

<p>Save changes, issue the <code className="code-text">python manage.py runserver</code>  command and go to my listings page.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image37.png"} alt='hj'  />
    </div>

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter16">⬅️ Chapter 16</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter18">Chapter 18 ➡️ </a></p>
</div>

    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter17;

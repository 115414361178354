import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter10 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter9">⬅️ Chapter 9</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter11">Chapter 11 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 10: Delete Listing Page</h1>
            </div>
        </div>
      </div>
      <p>To finish with the listings app, we will create a page for users to delete their listings.</p>

              <h3 class="mb-3"><strong>Delete Listing URL</strong></h3>
              <p>Open<code className="code-text"> urls.py </code>add the path to the delete listing page.
              </p>


<pre className="code-box">
<p>#listings/urls.py</p>

<p>from django.urls import path</p>
<p>from .import views</p>
<p> </p>
<p>app_name = 'listings'</p>
<p>urlpatterns = [</p>
             <p>   path(' ', views.index, name='index'),</p>
             <p>   path('all_listings/', views.all_listings, name='all_listings'),</p>
             <p>   path('new_listing/', views.new_listing, name='new_listing'),</p>
             <p>   path('all_listings/&#60;detail_id&#62;/', views.detail, name='detail'),</p>
             <p>   path('my_listings/', views.my_listings, name='my_listings'),</p>
             <p>   path('edit_listing/&#60;edit_id&#62;/', views.edit_listing, name='edit_listing'),</p>
             <p>   path('delete_listing/&#60;delete_id&#62;/', views.delete_listing, name='delete_listing'),</p>
             <p>]</p>


</pre>


<h3 class="mb-3"><strong>Delete Listing View</strong></h3>

              <p>Open<code className="code-text"> views.py </code> and create the view for the delete listing page.
              </p>


              <pre className="code-box">
<p>#listings/views.py</p>

<p>from django.shortcuts import render, redirect</p>
<p>from .forms import ListingForm</p>
<p>from .models import Listings</p>
<p>...</p>
<p>def delete_listing(request, delete_id):</p>
<p>    listing = Listings.objects.get(id=delete_id)</p>
<p> </p>
<p>    if request.method == 'POST':</p>
<p>        listing.delete()</p>
<p>        return redirect('listings:my_listings')</p>
<p> </p>
<p>    context = &#123;'listing': listing&#125;</p>
<p>    return render(request, 'listings/delete_listing.html', context)</p>
</pre>
         

<p>We give users the option to choose if they want to delete a listing or not. Users will need to make a POST request to go ahead and delete a listing.</p>




<h3 class="mb-3"><strong>Delete Listing Template</strong></h3>
              <p>Create a template called<code className="code-text"> delete_listing.html </code> in the templates folder and add the following code.
              </p>



              <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ ├── index.html</p>
<p>│ │ ├── all_listings.html</p>
<p>│ │ ├── new_listing.html</p>
<p>│ │ ├── detail.html</p>
<p>│ │ ├── my_listings.html</p>
<p>│ │ ├── edit_listing.html</p>
<p>│ │ └── delete_listing.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>



<pre className="code-box">
<p>&#60;--listings/templates/listings/delete_listing.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>

<p>&#60;div class='main'&#62;</p>
<p>    &#60;form method='post'&#62;</p>
<p>       &#60;div class='container'&#62;</p>
<p>         &#123;% csrf_token %&#125;</p>
<p>         &#60;h3&#62;Do you want to delete &#60;b&#62;&#123;&#123;listing.title&#125;&#125;&#60;/b&#62;listing?&#60;/h3&#62;</p>
<p>         &#60;p&#62;</p>
<p>         &#60;input type='submit' class='btn' value='Delete' /&#62;</p>
<p>         &#60;a class='btn'&#62;</p>
<p>         href="&#123;% url 'listings:my_listings' %&#125;"&#62;Cancel</p>
<p>         &#60;/a&#62;</p>
<p>         &#60;/p&#62;</p>
<p>         &#60;/div&#62;</p>
<p>    &#60;/form&#62;</p>
<p>&#60;/div&#62;</p>
<p> </p>
<p>&#123;% endblock content%&#125;</p>
</pre>


<p>We display a message which will prompt users to choose between two options. If users click 'Delete', the listing will be deleted. On the other hand, 'Cancel' redirects users to my listings page.</p>

<h3 class="mb-3"><strong>My Listings - Delete</strong></h3>
          
<p>Open <code className="code-text">my_listings.html</code> and activate the delete button</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/my_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h2&#62;MY LISTINGS:&#60;/h2&#62;</p>

<p>&#60;div class="row"&#62;</p>
<p>  &#60;div class="col"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>     &#60;table class="table"&#62;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;th&#62;Product&#60;/th&#62;</p>
<p>         &#60;th&#62;Date Listed&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>         &#60;th&#62;&#60;/th&#62;</p>
<p>       &#60;tr&#62;</p>
<p>       &#123;% for my_listing in my_listings %&#125;</p>
<p>       &#60;tr&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.title &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#123;&#123; my_listing.list_date &#125;&#125;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn"</p>
<p>           href="&#123;% url 'listings:detail' my_listing.id %&#125;"&#62;View</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn"</p>
<p>           href="&#123;% url 'listings:edit_listing' my_listing.id %&#125;"&#62;Edit</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>         &#60;td&#62;&#60;a class="btn"</p>
<p>           href="&#123;% url 'listings:delete_listing' my_listing.id %&#125;"&#62;Delete</p>
<p>         &#60;/a&#62;&#60;/td&#62;</p>
<p>       &#60;/tr&#62;</p>
<p>       &#123;% endfor %&#125;</p>
<p>      &#60;/table&#62;</p>
<p>       &#60;div class="container"&#62;</p>
<p>         &#60;a class="btn"&#62;</p>
<p>           href="&#123;% url 'listings:new_listing' %&#125;"&#62;New Listing</p>
<p>         &#60;/a&#62;</p>
<p>       &#60;/div&#62;</p>
<p>     &#60;/div&#62;</p>
<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>


<p>Save changes and issue the <code className="code-text">python manage.py runserver </code> command.</p>

<p>Go to my listings page, and try to delete a listing.</p>

<p>You should see a message prompting you to choose if you want to delete the listing or not.</p>

   <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image17.png"} alt='hj'  />
    </div>  
<p>When you delete a listing, it disappears from both, the listings page and my listings page</p>

<p>The delete feature completes all essential functions of the listings app. However, there’s always room for improvement, so feel free to add more features to the app if you like.</p>

<p>Next chapter, we will move away from the listings app and create the users app, so that other users can sign up and use the website to create listings.</p>

<div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter9">⬅️ Chapter 9</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter11">Chapter 11 ➡️ </a></p>
</div>

    </div>



  
   
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter10;

import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter19 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter18">⬅️ Chapter 18</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter20">Chapter 20 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 19: Styling with Bootstrap (Part IIII)</h1>
            </div>
        </div>
      </div>

      <p>In this chapter, we will finish styling the rest of the pages of the website such as the login and register page. We will also style the filter on the listings page.</p>
    

          <h3 class="mb-3"><strong>Login Page - Bootstrap/Django Crispy Form</strong></h3>


<p>Open <code className="code-text">login.html</code>, and update the code as shown.</p>



<pre className="code-box">
<p>&#60;--users/templates/registration/login.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;% load crispy_forms_tags %&#125;</p>
<p> </p>
<p>&#123;% block_content %&#125;</p>
<p>&#60;h1 class="text-center p-3"&#62;Login&#60;/h1&#62;</p>
<p>&#60;div class="container p-3" style="width:35%;"&#62;</p>
<p>  &#60;form method='POST'&#62; action="&#123;% url 'users':login' %&#125;"&#62;</p>
<p>        &#123;% csrf_token %&#125;</p>
<p>        &#123;&#123; form|crispy &#125;&#125;</p>
<p>        &#60;div class="container text-center"&#62;</p>
<p>          &#60;button name='submit' class='btn btn-warning'&#62;Submit&#60;/button&#62;</p>
<p>          &#60;input type='hidden' name="next" value="&#123;% url 'listings':index' %&#125;"/&#62;</p>
<p>        &#60;/div&#62;</p>
<p>  &#60;/form&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content %&#125;</p>
</pre>

<p>Save changes and go to the login page to see the updated form.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image41.png"} alt='hj'  />
    </div>


    <h3 class="mb-3"><strong>Register Page - Bootstrap/Django Crispy Form</strong></h3>


<p>Open <code className="code-text">register.html</code>, and update the code as shown.</p>


<pre className="code-box">
<p>&#60;--users/templates/registration/register.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;% load crispy_forms_tags %&#125;</p>
<p> </p>
<p>&#123;% block content %&#125;</p>
<p>&#60;h2 class="text-center p-3"&#62;Create Account&#60;/h2&#62;</p>
<p>&#60;div class="container p-3" style="width:40%;"&#62;</p>
<p>  &#60;form method="post" action="&#123;% url 'users:register' %&#125;"&#62;</p>
<p>        &#123;% csrf_token %&#125;</p>
<p>        &#123;&#123; form|crispy &#125;&#125;</p>
<p>        &#60;div class="container text-center"&#62;</p>
<p>        &#60;button name='submit' class='btn btn-warning'&#62;Register&#60;/button&#62;</p>
<p>        &#60;input type='hidden' name="next" value="&#123;% url 'users':login' %&#125;"/&#62;</p>
<p>        &#60;/div&#62;</p>
<p>  &#60;/form&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content %&#125;</p>
</pre>



<p>Once again, save changes and go to the register page.</p>

   <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image42.png"} alt='hj'  />
    </div>


    <h3 class="mb-3"><strong>Search Filter - Bootstrap/Django Crispy Form</strong></h3>

<p>Open <code className="code-text">all_listings.html</code> and update the code for the search filter.</p>


<pre className="code-box"><p>&#60;!--listings/templates/listings/all_listings.html--&#62;</p>
   <p>&#123;% extends "listings/base.html" %&#125;</p>
   <p>&#123;% load crispy_forms_tags %&#125;</p>
<p>&#123;%block content%&#125;</p>
  <p>  &#60;div class="container text-center p-3 " style="width: 500px;"&#62;</p>
    <p>    &#60;h1 class="text-dark"&#62;Listings&#60;/h1&#62;</p>
    <p>    &#60;div class="form-group"&#62;</p>
      <p>      &#60;div class="card card-body bg-light text-dark rounded" style="width: 100%;"&#62;</p>
        <p>        &#60;form method="get"&#62;</p>
        <p>          &#60;div class="row"&#62;</p>
        <p>           &#60;div class="col-md-3"&#62;</p>
          <p>             &#123;&#123; my_Filter.form.condition|as_crispy_field &#125;&#125;</p>
          <p>           &#60;/div&#62;</p>
          <p>           &#60;div class="col-md-3"&#62;</p>
          <p>             &#123;&#123; my_Filter.form.state|as_crispy_field &#125;&#125;</p>
          <p>           &#60;/div&#62;</p>
          <p>           &#60;div class="col-md-3"&#62;</p>
          <p>             &#123;&#123; my_Filter.form.city|as_crispy_field &#125;&#125;</p>
          <p>           &#60;/div&#62;</p>
          <p>           &#60;div class="col-md-3 p-2"&#62;</p>
          <p>             &#60;button class="btn btn-warning border-dark" type="submit"&#62;Search</p>
          <p>             &#60;/button&#62;</p>
          <p>           &#60;/div&#62;</p>
          <p>          &#60;/div&#62;</p>
        <p>         &#60;/form&#62;</p>
      <p>      &#60;/div&#62;</p>
    <p>    &#60;/div&#62;</p>
  <p>  &#60;/div&#62;</p> 
  <p>&#60;div class="container"&#62;</p>
  <p>  &#60;div class="card card-body bg-light text-dark text-center p-3"&#62;</p>                 
    <p>    &#123;% for listing in all_listings %&#125;</p>                  
      <p>    &#60;p&#62;</p>
        <p>    &#60;img class="card-img-right img-thumbnail"  src="&#123;&#123; listing.main_photo.url &#125;&#125;"</p> 
        <p>     width=300px height=200px style="max-height:200px" alt="connect"&#62;</p>
        <p>    &#60;img class="card-img-right img-thumbnail" src="&#123;&#123; listing.photo_1.url &#125;&#125;"</p> 
        <p>     width=300px height=200px style="max-height:200px" alt="connect"&#62;</p>
      <p>    &#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62; &#123;&#123; listing.title &#125;&#125;&#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62; &#123;&#123; listing.condition &#125;&#125;&#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Sale Type:&#60;/b&#62; &#123;&#123; listing.sale_type &#125;&#125;&#60;/p&#62;</p>
      <p>    &#60;p&#62;&#60;b&#62;Price:&#60;/b&#62; $ &#123;&#123; listing.price|floatformat:2 &#125;&#125;&#60;/p&#62;</p>  
      <p>    &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62; &#123;&#123; listing.city &#125;&#125;, &#123;&#123; listing.state &#125;&#125;&#60;/p&#62;</p>  
      <p>    &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62; &#123;&#123; listing.list_date &#125;&#125;&#60;/p&#62;    </p>           
      <p>    &#60;ul&#62;&#60;a class="btn btn-warning border-dark" </p>  
      <p>          href="&#123;% url 'cards:detail' listing.id %&#125;"&#62;View Listing</p>  
      <p>    &#60;/a&#62;&#60;/ul&#62;</p>  
      <p>    &#60;hr&#62;            </p>       
      <p>    &#123;% endfor %&#125;  </p>                  
      <p>  &#60;/div&#62;</p>  
      <p>&#60;/div&#62;</p>  
                  
      <p>&#123;%endblock content%&#125;</p>          
          </pre>

          <p>Save changes, issue the <code className="code-text">python manage.py runserver</code> command</p>

<p>Go to the listings page to see the updated search filter.</p>



<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image43.png"} alt='hj'  />
    </div>

    <p>We have finished styling all pages of the website. In the following chapters, we will work on
 deploying the app, so that people can use it.</p>
 <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter18">⬅️ Chapter 18</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter20">Chapter 20 ➡️ </a></p>
</div>
    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter19;

import React from 'react';
import "./index.css";




const Content = () => {


    return(


       <>
        
        <div className='container-content'>
        <div class='mini-container1'>
  <div class='css-1regj17'>
    <div class='css-9aafwz'><span class='category-badge'>Course 📚</span><span class='category-badge'>Python 🐍</span></div>
    <div class='css-1kcle2a'>
    <hr></hr>
      <h2 class='heading-card'>Django Takeoff!</h2>
      
      <p class='description'>The focus of this course is to learn Django web development by example. You will be developing a web application step-by-step by setting up URLs, views, and templates of different web pages.</p>
      <hr></hr>
    </div>
  </div>
  <div class='button-container-card'>
    <button class='btn-card'>
      <a href='/takeoff' target="_blank">Learn More</a>
    </button>
  </div>
  {/*<p class='badge'>Published: 01/10/2023</p>*/}
</div>

<div class='mini-container1'>
  <div class='css-1regj17'>
    <div class='css-9aafwz'><span class='category-badge'>Resource 🔗</span></div>
    <div class='css-1kcle2a'>
    <hr></hr>
      <h2 class='heading-card'>Youtube Channel</h2>
      
      <p class='description'>Looking for more web development content. Check out my channel to start your developer journey.</p>
      <hr></hr>
    </div>
  </div>
  <div class='button-container-card'>
    <button class='btn-card'>
    <a href="https://www.youtube.com/channel/UChZR16e1XwZXy2yrk_8ymFg" target="_blank">Learn More</a>
    </button>
  </div>

</div>
            
<div class='mini-container1'>
  <div class='css-1regj17'>
    <div class='css-9aafwz'><span class='category-badge'>Resource 🔗</span></div>
    <div class='css-1kcle2a'>
    <hr></hr>
      <h2 class='heading-card'>HireJrDevs</h2>
      
      <p class='description'>Looking for a junior developer job? Hire Jr Devs is the right place to jumpstart your junior developer career with some exciting opportunities at top companies who are willing to bet on you.</p>
      <hr></hr>
    </div>
  </div>
  <div class='button-container-card'>
    <button class='btn-card'>
    <a href="https://www.hirejrdevs.com" target="_blank">Learn More</a>
    </button>
  </div>

</div>
<div class='mini-container1'>
  <div class='css-1regj17'>
    <div class='css-9aafwz'><span class='category-badge'>Resource 🔗</span></div>
    <div class='css-1kcle2a'>
    <hr></hr>
      <h2 class='heading-card'>ResumeBoostAI</h2>
      
      <p class='description'>Resume Boost AI Generator is a software tool that uses artificial intelligence and natural language processing algorithms to create effective and optimized resumes for job seekers. It analyzes the job seeker's skills, experience, and career goals, and generates a customized resume that highlights their strengths and achievements.</p>
      <hr></hr>
    </div>
  </div>
  <div class='button-container-card'>
    <button class='btn-card'>
    <a href="https://resumeboostai.com" target="_blank">Learn More</a>
    </button>
  </div>

</div>
<div class='mini-container1'>
  <div class='css-1regj17'>
    <div class='css-9aafwz'><span class='category-badge'>Resource 🔗</span></div>
    <div class='css-1kcle2a'>
    <hr></hr>
      <h2 class='heading-card'>WebsiteColorsAI</h2>
      
      <p class='description'>WebsiteColorsAI saves a ton of time when trying to choose a color palette for your website, you can enter the URL of any website, and the AI tool immediately gets the colors used on the website which speeds up your own design workflow and frees up a lot of time to focus on other tasks.</p>
      <hr></hr>
    </div>
  </div>
  <div class='button-container-card'>
    <button class='btn-card'>
    <a href="https://websitecolorsai.com" target="_blank">Learn More</a>
    </button>
  </div>

</div>
<div class='mini-container1'>
  <div class='css-1regj17'>
    <div class='css-9aafwz'><span class='category-badge'>Resource 🔗</span></div>
    <div class='css-1kcle2a'>
    <hr></hr>
      <h2 class='heading-card'>LogoStoreAI</h2>
      
      <p class='description'>Extensive library of AI-generated logos and images assets for businesses and individuals looking to find stunning, unique logos to elevate your brand.</p>
      <hr></hr>
    </div>
  </div>
  <div class='button-container-card'>
    <button class='btn-card'>
    <a href="https://logostoreai.com" target="_blank">Learn More</a>
    </button>
  </div>

</div>

           
        </div>







    

</>
        
  
      
    )
}

export default Content;
import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter18 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter17">⬅️ Chapter 17</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter19">Chapter 19 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 18: Styling with Bootstrap (Part III)</h1>
            </div>
        </div>
      </div>
    

          <h3 class="mb-3"><strong>Django Crispy Forms </strong></h3>
<p>This app helps us manage Django forms. Plus, it provides us with a base template style to render elegant forms.</p>

<p>We'll use this app to style some of the forms on the website.</p>
   
<p>Go to the terminal window and issue the following command.</p>

   <pre className="code-box">
   <p>pip install crispy-bootstrap5</p>         
   </pre>

<p>Go to <code className="code-text">settings.py</code>, add <code className="code-text">crispy_forms</code> and <code className="code-text">crispy_bootstrap5</code> to the <code className="code-text">INSTALLED_APPS</code> section, and add the lines of code shown below too.</p>


<pre className="code-box">
<p>#example/settings.py</p>
<p>...</p>
<p>INSTALLED_APPS = [</p>
<p>   'django.contrib.admin',</p>
<p>   'django.contrib.auth',</p>
<p>   'django.contrib.contenttypes',</p>
<p>   'django.contrib.sessions',</p>
<p>   'django.contrib.messages',</p>
<p>   'django.contrib.staticfiles',</p>
<p>   'listings',</p>
<p>   'users',</p>
<p>   'django_filters'</p>
<p>   'crispy_forms',</p>
<p>   'crispy_bootstrap5'</p>
<p>]</p>
<p>CRISPY_ALLOWED_TEMPLATE_PACKS = "bootstrap5"</p>
<p>CRISPY_TEMPLATE_PACK = "bootstrap5"</p>
<p>...</p>
</pre>

<h3 class="mb-3"><strong>New Listing Page - Bootstrap/Django Crispy Form</strong></h3>

<p>Open <code className="code-text">new_listing.html</code> and make the following changes.</p>




<pre className="code-box">
<p>&#60;--listings/templates/listings/new_listing.html--&#62;</p>
<p> </p>

<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;% load crispy_forms_tags %&#125;</p>
<p>&#123;% block_content %&#125;</p>
<p>&#60;h2 class="p3 text-center"&#62;NEW LISTING&#60;/h2&#62;</p>
<p>&#60;div class="container" style="width: 50%;"&#62;</p>
<p>  &#60;form action="&#123;% url 'listings:new_listing' %&#125;"</p>
<p>      method="post"</p>
<p>      enctype="multipart/form-data"&#62;</p>
<p>      &#123;% csrf_token %&#125;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.title|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.product_type|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.condition|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.sale_type|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.city|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.state|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.zipcode|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.price|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.contact_email|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.main_photo|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.photo_1|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.photo_2|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="container text-center p-3"</p>
<p>        &#60;button type="submit" class="btn"&#62;Create Listing</p>
<p>        &#60;/button&#62;</p>
<p>      &#60;/div&#62;</p>
<p>  &#60;/form&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content %&#125;</p>
</pre>

<p>In order to activate Django Crispy Forms, we need to include the <code className="code-text">&#123;% load crispy_forms_tags %&#125;</code> tag.</p>

<p>If your main goal is to display the form without any modifications, you can use <code className="code-text">&#123;% form|crispy %&#125;</code> to save time.</p>

<p>We want to change the position of the form fields on the screen, so we have used <code className="code-text">&#123;&#123; field|as_crispy_field &#125;&#125;</code> for each field in the form.</p>

<p>Save changes and go to my listings page to create a new listing.</p>

   <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image38.png"} alt='hj'  />
    </div>


    <h3 class="mb-3"><strong>Edit Listing Page - Bootstrap/Django Crispy Form</strong></h3>

<p>Open <code className="code-text">edit_listing.html</code> and update the code as shown.</p>

<pre className="code-box">
<p>&#60;--listings/templates/listings/edit_listing.html--&#62;</p>
<p> </p>

<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;% load crispy_forms_tags %&#125;</p>
<p>&#123;% block_content %&#125;</p>
<p>&#60;h2 class="p3 text-center"&#62;EDITING&#60;/h2&#62;</p>
<p>&#60;div class="container" style="width: 50%;"&#62;</p>
<p>  &#60;form action="&#123;% url 'listings:edit_listing' %&#125;"</p>
<p>      method="post"</p>
<p>      enctype="multipart/form-data"&#62;</p>
<p>      &#123;% csrf_token %&#125;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.title|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.product_type|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.condition|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.sale_type|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.city|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.state|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.zipcode|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.price|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.contact_email|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.main_photo|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.photo_1|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="row"</p>
<p>        &#60;div class="col"&#62;&#123;&#123; form.photo_2|as_crispy_field &#125;&#125;&#60;/div&#62;</p>
<p>      &#60;/div&#62;</p>
<p>      &#60;div class="container text-center p-3"</p>
<p>        &#60;button type="submit" class="btn"&#62;Edit Listing</p>
<p>        &#60;/button&#62;</p>
<p>      &#60;/div&#62;</p>
<p>  &#60;/form&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;% endblock content %&#125;</p>
</pre>


<p>The code for the edit listing page is almost identical to the new listing page.</p>

<p>Save changes and go to my listings page. Edit a listing to make sure that the form works.</p>



<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image39.png"} alt='hj'  />
    </div>

    <h3 class="mb-3"><strong>Delete Listing Page - Bootstrap</strong></h3>


<p>Open <code className="code-text">delete_listing.html</code> and change the code as shown.</p>


<pre className="code-box">
<p>&#60;--listings/templates/listings/delete_listing.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p> </p>
<p>&#60;div class='main'&#62;</p>
<p>    &#60;form method='post'&#62;</p>
<p>         &#123;% csrf_token %&#125;</p>
<p>           &#60;div class='container text-center p-5'&#62;</p>
<p>             &#60;div class='card'&#62;</p>
<p>               &#60;h3 class='p-4'&#62;Do you want to delete &#60;b&#62;&#123;&#123;listing.title&#125;&#125;&#60;/b&#62;listing?&#60;/h3&#62;</p>
<p>               &#60;div class='container p-4'&#62;</p>
<p>                 &#60;input type='submit' class='btn btn-warning' value='Delete' /&#62;</p>
<p>                 &#60;a class='btn btn-danger'&#62;</p>
<p>                   href="&#123;% url 'listings:my_listings' %&#125;"&#62;Cancel&#60;/a&#62;</p>
<p>               &#60;/div&#62;</p>
<p>           &#60;/div&#62;</p>
<p>         &#60;/div&#62;</p>
<p>    &#60;/form&#62;</p>
<p>&#60;/div&#62;</p>
<p> </p>
<p>&#123;% endblock content%&#125;</p>
</pre>


<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>


<p>Go to my listings page and delete a listing to make sure that the form works.</p>

<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image40.png"} alt='hj'  />
    </div>

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter17">⬅️ Chapter 17</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter19">Chapter 19 ➡️ </a></p>
</div>

    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter18;

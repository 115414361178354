import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter4 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter3">⬅️ Chapter 3</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter5">Chapter 5 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 4 : Listings Page</h1>
            </div>
        </div>
      </div>
      
      <p>Let’s continue building the website by creating the listings page. We will display all listings on this page.
              </p>
              <p>We'll follow the same steps as in the previous chapter.
              </p>

              <p>Create a URL >> Create a view >> Create a template.
             </p>
         

             <h3  class="mb-3"><strong>Listings URL</strong></h3>

             <p>Open <code className="code-text">urls.py</code> and add the path to the listings page.</p>

             <p>I chose <code className="code-text">all_listings</code> for the name, but you can choose a different 
                name.
             </p>



             <pre className="code-box">
             
           
            <p>#listings/urls.py</p>
            <p>from django.urls import path</p>
            <p>from .import views</p>
            <p> </p>
                                          
            <p>app_name = 'listings' </p>
            <p>urlpatterns = [</p>
            <p>  path(' ', views.index, name='index'),</p>
            <p>  path('all_listings/', views.all_listings, name='all_listings'),</p>
            <p>]</p>
</pre>


<h3 class="mb-3"><strong>Listings View</strong></h3>
          
          <p>Open <code className="code-text">views.py</code> and add the following code.
          </p>
        
  
          <h5 class="mb-3"><strong>Full Code Version</strong></h5>
  
          <pre className="code-box">
             
           
             <p>#listings/views.py </p>
             <p>from django.shortcuts import render</p>
             <p>from .models import Listings</p>
             <p> </p>
                                           
             <p>def index(request):</p>
             <p>  return render(request, 'listings/index.html') </p>
             <p> </p>
             <p>def all_listings(request):</p>
             <p>  all_listings = Listings.objects.order_by('-list_date')</p>
             <p> </p>
             <p>  context = &#123;"all_listings" &#58; all_listings&#125;</p>
             <p>  return render(request, 'listings/all_listings.html', context)</p>
 </pre>


 <p>The code will get lengthy and more convoluted going forward, so to avoid confusion and improve readability. I will use <code className="code-text">... </code>
            to show that there’s more code in the file.</p>
         
          <p>If in doubt about the code, please check the source code files which have the full code.
           </p>

          <h5 class="mb-3"><strong>Shortened Code Version</strong></h5>

          <pre className="code-box">
             
           
             <p>#listings/views.py </p>
             <p>from django.shortcuts import render</p>
             <p>from .models import Listings</p>
             <p> </p>
                                           
             <p>...</p>
             <p>def all_listings(request):</p>
             <p>  all_listings = Listings.objects.order_by('-list_date')</p>
             <p> </p>
             <p>  context = &#123;"all_listings" &#58; all_listings&#125;</p>
             <p>  return render(request, 'listings/all_listings.html', context)</p>
 </pre>

 <h5 class="mb-3"><strong>from .models import model_name</strong></h5>
          
          <p>Imports the model into <code className="code-text">views.py</code>. The data from the model
             needs to be passed to the template in order to be used.
          </p>

       


          <h3  class="mb-3"><strong>Database Queries</strong></h3>
          
          <p>In order to create, retrieve, update, and delete objects from the database,
             we query the database.</p>
             
            <p>Django provides us with a database-abstraction
             API in order to perform the actions mentioned before also known as CRUD.</p>
             
           <p>Queries are used to manage the data stored in the database.
          </p>

          <p>Let's analyze the snippet of code for the listings page.</p>

          <pre className="code-box">

             <p>def all_listings(request):</p>
             <p>  all_listings = Listings.objects.order_by('-list_date')</p>
             <p> </p>
             <p>  context = &#123;"all_listings" &#58; all_listings&#125;</p>
             <p>  return render(request, 'listings/all_listings.html', context)</p>
 </pre>


 <p>The entire query prompts the database for all objects in the <code className="code-text">Listings</code> model arranged by date in reverse order (-).
            All objects will be stored in the variable <code className="code-text">all_listings</code> which will be stored in the <code className="code-text">context = &#123;&#125;</code> dictionary 
            and passed to the <code className="code-text">all_listings</code> template by using the render method.</p>
            
         <p>I listed some common queries below, but feel free to check out the official Django documentation for more.
       </p>

</div>



    <div class="container">
      <table class="table table-sm table-bordered mytable">

        <thead class="thead-light">
          <tr>
            <th scope="col-4"><p className="table-title">Query</p></th>
            <th scope="col-8"><p className="table-title">Explanation</p></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <th scope="col-4"><p class="table-left">variable_name = model_name.objects.all( )</p></th>
            <th scope="col-8"><p class="table-left">Returns all objects for specified model_name.</p></th>
          </tr>
          <tr>
          <th scope="col-4"><p class="table-left">variable_name = model_name.objects.first( )</p></th>
            <th scope="col-8"><p class="table-left">Returns first object.</p></th>
          </tr>
          <tr>
          <th scope="col-4"><p class="table-left">variable_name = model_name.objects.last( )</p></th>
            <th scope="col-8"><p class="table-left">Returns last object.</p></th>
          </tr>
          <tr>
          <th scope="col-4"><p class="table-left">variable_name = model_name.objects.get(name='Sam')</p></th>
            <th scope="col-8"><p class="table-left">Returns object/objects that match name= ‘Sam’.</p></th>
          </tr>
          <tr>
          <th scope="col-4"><p class="table-left">variable_name = model_name.objects.order_by('list_date')</p></th>
            <th scope="col-8"><p class="table-left">Returns object/objects ordered by list_date.</p></th>
          </tr>
        </tbody>

      </table>


      <h3 class="mb-3"><strong>Listings Template</strong></h3>

<p>Create a template named <code className="code-text">all_listings.html</code> in the same folder as the index template.</p>


<pre className="code-box">
<p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ └── index.html</p>
<p>│ │ └── all_listings.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>


<p>Open <code className="code-text">all_listings.html</code> and add the following code.
      </p>



      <pre className="code-box">
<p>&#60;--listings/templates/listings/all_listings.html--&#62;</p>
<p> </p>
<p>&#60;h1&#62;LISTINGS&#60;/h1&#62;</p>
<p>&#60;div class="col"&#62;</p>
<p>  &#60;div class="card"&#62;</p>
<p>   &#123;% for listing in all_listings %&#125;</p>
<p> </p>
<p>   &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62; &#123;&#123; listing.title &#125;&#125;&#60;/p&#62;</p>
<p>   &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62; &#123;&#123; listing.condition &#125;&#125;&#60;/p&#62;</p>
<p>   &#60;p&#62;&#60;b&#62;Sale Type:&#60;/b&#62; &#123;&#123; listing.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>   &#60;p&#62;&#60;b&#62;Price&#60;/b&#62; &#123;&#123; listing.price|floatformat:2 &#125;&#125;&#60;/p&#62;</p>
<p>   &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62; &#123;&#123; listing.city &#125;&#125;,&#123;&#123; listing.state &#125;&#125;&#60;/p&#62;</p>
<p>   &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62; &#123;&#123; listing.list_date &#125;&#125;&#60;/p&#62;</p>
<p> </p>
<p>   &#60;ul&#62;</p>
<p>     &#60;a class="btn" href=""&#62;View Listing&#60;/a&#62;</p>
<p>   &#60;/ul&#62;</p>

<p>   &#60;hr&#62;</p>

<p>   &#123;% endfor %&#125;</p>
<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
</pre>



<h5 class="mb-3"><strong>&#123;% ... %&#125;</strong></h5>
          
          <p>Django provides special built-in template tags that can be used in templates
             to run Python code.
          </p>
    
          <h5 class="mb-3"><strong>&#123;% for listing in all_listings  %&#125; - &#123;% endfor %&#125;</strong></h5>
              
          <p>We loop through the data in <code className="code-text">all_listings</code>. Then, we display specific fields using dot notation
             to access the dictionary data. For instance, <code className="code-text">listing.title</code> will retrieve the title of the listing, <code className="code-text">listing.price</code> will give us the price, etc.
          </p>
    
          <p>The &#123;% endfor %&#125; clause ends the loop.</p>
    
          <p>There is a link to view each listing, however, it doesn't lead anywhere for now,
             but we will activate it later. </p>
          
          <p>Run server by issuing <code className="code-text">python manage.py runserver</code> and go to 
            <a class="text-primary" href="http://127.0.0.1:8000/all_listings/" 
            target="_blank"> http://127.0.0.1:8000/all_listings/</a>.
          </p>
    
          <p>All the listings created earlier should appear. If you don't see any listings, go over the chapter again and troubleshoot. </p>
    

          <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image8.png"} alt='hj'  />
    </div>  

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter3">⬅️ Chapter 3</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter5">Chapter 5 ➡️ </a></p>
</div>



    </div>



  
   
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter4;

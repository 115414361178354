import React from 'react';
import "./index.css";




const TableContents = () => {


    return(


       <>
      



<div className='container-table-contents'>
<div class="containerr">
			<h1 className='course-title'>Django Takeoff! Course</h1>
			<div class="badges">
				<span class="badge">Django</span>
				<span class="badge">Python</span>
				<span class="badge">HTML</span>
				<span class="badge">CSS</span>
			</div>
			<p class="description-title">The focus of this course is to learn Django web development by example. You will be developing a web application step-by-step by setting up URLs, views, and templates of different web pages. The course walks you through various useful concepts to give your app a real look. For example, creating and managing user accounts, implementing search filters, uploading images, Bootstrap styling, and cloud deployment using AWS (Amazon Web Services) and Heroku.   Every concept is presented with an executable environment so that you get hands-on experience. By the end of the course, you will have a real web application using Django. This is a great course for beginners who want to learn the basics of Django and start building along the way.</p>
      <div className='image-right'>
              
      <a href="https://www.youtube.com/watch?v=B0rG2SQOBFo&list=PLa0hmV2hTFg4-6BlPzObtdYGB7Tajtui_" target="_blank"> <img className="logos-companies" src={"https://bucketcito.s3.amazonaws.com/img/youtube.png"} alt='channel to learn web development' /></a>
      <a href="https://www.amazon.com/Django-Takeoff-Quick-Learning-Development-ebook/dp/B08H4W2WLD" target="_blank">  <img className="logos-companies2" src={"https://bucketcito.s3.amazonaws.com/img/amazon.png"} alt='amazon book django takeoff' /></a>
      <a href="https://www.educative.io/courses/django-takeoff-develop-modern-apps" target="_blank"> <img className="logos-companies3" src={"https://bucketcito.s3.amazonaws.com/img/educative.png"} alt='django takeoff! develop modern applications' /></a>
      <a href="https://twitter.com/balt1794" target="_blank"> <img className="logos-companies4" src={"https://bucketcito.s3.amazonaws.com/img/twitter.png"} alt='twitter for Bryam Loaiza' /></a>
              </div>
              
		</div>
  
<div className="row">

<h2 class="text-center mb-4 ">Table of Contents</h2>  
          <div class="col-lg-12 col-xl-12">
         
 <div class="singlet">

  <div class="row">
    <div class="col"><a class="reg text-dark " href="/takeoff/chapter1"><i class="bi bi-check-circle-fill" ></i> Chapter 1 : Django Web App Setup </a></div>
    <div class="col"><a class="reg text-dark " href="/takeoff/chapter2"><i class="bi bi-check-circle-fill" ></i> Chapter 2 : Django Basics </a></div>
    
    <div class="w-100"></div>
    <div class="col"><a class="reg text-dark " href="/takeoff/chapter3"><i class="bi bi-check-circle-fill" ></i> Chapter 3 : Homepage </a></div>
    <div class="col"><a class="reg text-dark " href="/takeoff/chapter4"><i class="bi bi-check-circle-fill" ></i> Chapter 4 : Listings Page </a></div>
    
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="/takeoff/chapter5"><i class="bi bi-check-circle-fill" ></i> Chapter 5: New Listing Page </a></div>
  <div class="col"><a class="reg text-dark " href="takeoff/chapter6"><i class="bi bi-check-circle-fill" ></i> Chapter 6 : Navbar </a></div>
    
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="takeoff/chapter7"><i class="bi bi-check-circle-fill" ></i> Chapter 7 : Detail Listing Page </a></div>
  <div class="col"><a class="reg text-dark " href="takeoff/chapter8"><i class="bi bi-check-circle-fill" ></i> Chapter 8 : My Listings Page </a> </div>
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="takeoff/chapter9"><i class="bi bi-check-circle-fill" ></i> Chapter 9 : Edit Listing Page </a> </div>
  <div class="col"><a class="reg text-dark " href="takeoff/chapter10"><i class="bi bi-check-circle-fill" ></i> Chapter 10 : Delete Listing Page </a></div>
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="takeoff/chapter11"><i class="bi bi-check-circle-fill" ></i>  Chapter 11 : Users App </a> </div>
  <div class="col"><a class="reg text-dark " href="/takeoff/chapter12"><i class="bi bi-check-circle-fill" ></i>  Chapter 12 : Register Page </a>  </div>
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="/takeoff/chapter13"><i class="bi bi-check-circle-fill" ></i>  Chapter 13 : Foreign Key</a></div>
    <div class="col"><a class="reg text-dark " href="/takeoff/chapter14"><i class="bi bi-check-circle-fill" ></i>  Chapter 14 : Images</a></div>
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="/takeoff/chapter15"><i class="bi bi-check-circle-fill" ></i>  Chapter 15 : Django Filters</a></div>
    <div class="col"><a class="reg text-dark " href="/takeoff/chapter16"><i class="bi bi-check-circle-fill" ></i>  Chapter 16: Styling with Bootstrap (Part I)</a></div>
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="takeoff/chapter17"><i class="bi bi-check-circle-fill" ></i>  Chapter 17: Styling with Bootstrap (Part II)</a></div>
  <div class="col"><a class="reg text-dark " href="takeoff/chapter18"><i class="bi bi-check-circle-fill" ></i>  Chapter 18: Styling with Bootstrap (Part III)</a></div>
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="takeoff/chapter19"><i class="bi bi-check-circle-fill" ></i>  Chapter 19: Styling with Bootstrap (Part IIII)</a></div>
    <div class="col"><a class="reg text-dark " href="takeoff/chapter20"><i class="bi bi-check-circle-fill" ></i>  Chapter 20: AWS - Amazon Relational Database Service (RDS)</a></div>
  </div>
  <div class="row">
  <div class="col"><a class="reg text-dark " href="takeoff/chapter21"><i class="bi bi-check-circle-fill" ></i>  Chapter 21: AWS - Amazon S3 Buckets</a></div>
    <div class="col"><a class="reg text-dark " href="takeoff/chapter22"><i class="bi bi-check-circle-fill" ></i>  Chapter 22: Heroku Deployment</a></div>
  </div>
  

  
            </div>
           

          </div>
          
        </div>

</div>


    
       
        
         
            
        

    

</>
        
  
      
    )
}

export default TableContents;
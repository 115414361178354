import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter16 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter15">⬅️ Chapter 15</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter17">Chapter 17 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 16: Styling with Bootstrap (Part I)</h1>
            </div>
        </div>
      </div>
      <p>Bootstrap is a collection of CSS and JavaScript/jquery code used to build responsive websites.</p>

<p>The following chapters will focus on styling the website. We will use Bootstrap 5 for this website.</p>

<p>There are multiple ways to implement Bootstrap. We have been using <code className="code-text">pip install</code> for all packages up to this point, so this time we will do something different and implement Bootstrap 5 by getting the required links and sticking them in the <code className="code-text">base.html</code> template.</p>

<p>Follow the link below and copy the starter template shown on the page which already comes with the links needed.</p>

<p><a class="text-primary" href="https://getbootstrap.com/docs/5.0/getting-started/introduction/" 
            target="_blank">Bootstrap5</a>
          </p>

<p>Additionally, we will add to the template the link to import Bootstrap icons which can be found towards the bottom of the page below.</p>

<p><a class="text-primary" href="https://icons.getbootstrap.com" 
            target="_blank">Bootstrap Icons</a>
          </p>

          <h3 class="mb-3"><strong>Navbar - Bootstrap</strong></h3>

   <p>Open <code className="code-text">base.html</code>, paste the starter template, and arrange the code as shown.</p>

   <pre className="code-box">#listings/templates/listings/base.html
<p> </p>
<p>&#60;!doctype html&#62;</p>
<p>&#60;html lang="en"&#62;</p>
<p>&#60;head&#62;</p>
<p>  &#60;meta charset="utf-8"&#62;</p>
<p>  &#60;meta name="viewport" content="width=device-width, initial-scale=1"&#62;</p>
<p>  &#60;link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" </p>
  <p>        rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"</p>
  <p>        crossorigin="anonymous"&#62;</p>
<p>  &#60;link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"&#62;</p>
<p>  &#60;title&#62;BikeFinder&#60;/title&#62;</p> 
<p>&#60;/head&#62;</p> 
      
<p>&#60;body&#62;</p>  
<p> &#60;nav class="navbar navbar-expand-lg navbar-dark bg-dark"&#62;</p>
  <p>   &#60;div class="container-fluid"&#62;</p> 
  <p>     &#60;a class="navbar-brand" </p> 
      
        <p>         href="&#123;% url 'listings:index' %&#125;"&#62;&#60;h3 class="text-warning"&#62;BikeFinder&#60;/h3&#62;</p>
        <p>     &#60;/a&#62; </p>   
      
    <p>     &#60;button class="navbar-toggler"</p>
    <p>         type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"   </p>
    <p>         aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"&#62;</p>
    <p>         &#60;span class="navbar-toggler-icon"&#62;&#60;/span&#62;</p> 
    <p>     &#60;/button&#62;</p>
    <p>   &#60;div class="collapse navbar-collapse" id="navbarNav"&#62;</p>
    <p>     &#60;ul class="navbar-nav"&#62;</p>
    <p>       &#60;li class="nav-item"&#62;</p> 
    <p>         &#60;a class="nav-link" href="&#123;% url 'listings:all_listings' %&#125;"&#62;Listings&#60;/a&#62;</p>  
    <p>       &#60;/li&#62;</p>     
    <p>     &#60;/ul&#62;</p>      
    <p>     &#60;ul class="navbar-nav ml-auto"&#62;</p>  
    <p>     &#123;% if user.is_authenticated %&#125;</p>  
    <p>       &#60;li class="nav-item"&#62;</p>  
    <p>         &#60;a class="nav-link" href="&#123;% url 'listings:my_listings' %&#125;"&#62;My Listings&#60;/a&#62;</p>    
    <p>       &#60;/li&#62;</p>
    <p>     &#123;% else %&#125;</p>      
    <p>       &#60;li class="nav-item"&#62;</p> 
    <p>         &#60;a class="nav-link" href="&#123;% url 'users:register' %&#125;"&#62;Register&#60;/a&#62;</p>   
    <p>       &#60;/li&#62;</p>
    <p>     &#123;% endif %&#125;</p>
    <p>     &#60;/ul&#62;</p>      
    <p>   &#60;/div&#62;</p>
    <p>     &#123;% if user.is_authenticated %&#125;</p>
    <p>         &#60;p class="text-white mt-3 px-4"&#62;&#123;&#123; user.username &#125;&#125;&#60;/p&#62;</p>  
    <p>         &#60;button class="btn btn-sm btn-warning" type="submit"&#62;</p>    
    <p>         &#60;a class="nav-link text-dark"</p>     
    <p>             href="&#123;% url 'users:log_out' %&#125;"&#62;&#60;strong&#62;Logout&#60;/strong&#62;</p>    
    <p>         &#60;/a&#62;&#60;/button&#62;</p>          
    <p>     &#123;% else %&#125;</p>    
    <p>         &#60;button class="btn btn-sm btn-warning" type="submit"&#62;</p>    
    <p>         &#60;a class="nav-link text-dark"</p>     
    <p>             href="&#123;% url 'users:login' %&#125;"&#62;&#60;strong&#62;Login&#60;/strong&#62;</p>    
    <p>         &#60;/a&#62;&#60;/button&#62;</p>          
    <p>     &#123;% endif %&#125;</p>    
    <p>    &#60;/div&#62;</p>      
    <p>  &#60;/nav&#62;</p>     
    <p>&#60;script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" </p>      
    <p>        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"</p>    
    <p>        crossorigin="anonymous"&#62;</p>
    <p>&#60;/script&#62;</p>
    <p>&#60;/body&#62;</p>
    <p>&#60;/html&#62;</p>
    <p>&#123;%block content%&#125;</p>
    <p>&#123;%endblock content%&#125;</p>              
          </pre>


<p>There is a lot going on in the code above, so I recommend opening the source code files in a code editor such as Visual Studio Code to visualize it better.
</p>

<p>The code for the navbar has been updated to use Bootstrap 5 and placed inside the starter template.</p>

<p>Check the navbar section to see an explanation of all the styles and elements used in the code.
</p>

<p><a class="text-primary" href="https://getbootstrap.com/docs/5.0/components/navbar/" 
            target="_blank">Navbar-Bootstrap5</a>
          </p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>

   <p>Go to the homepage to see the new navbar.</p>

   <div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image33.png"} alt='hj'  />
    </div>


    <h3 class="mb-3"><strong>Homepage - Bootstrap</strong></h3>

<p>Open <code className="code-text">index.html</code> and make the following changes to the template.</p>


<pre className="code-box">&#60;!--listings/templates/listings/index.html--&#62;
<p> </p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;main&#62;</p>
<p>  &#60;div class="container py-4"&#62;</p>
<p>    &#60;div class="p-5 mb-4 bg-warning rounded-3"&#62;</p>
<p>      &#60;div class="container-fluid py-5"&#62;</p>
<p>        &#60;h1 class="display-5 fw-bold"&#62;BIKE FINDER&#60;/h1&#62;</p>
<p>        &#60;p class="col-md-8 fs-4"&#62;Find the latest bikes,</p>
<p>         parts, accessories, and more.&#60;/p&#62;</p>
<p>        &#60;button class="btn btn-dark btn-lg"</p>
<p>                type="button"&#62;&#60;a class="text-light"</p> 
<p>                href="&#123;% url 'listings:all_listings' %&#125; "&#62;Explore &#60;/a&#62;</p> 
<p>        &#60;/button&#62;</p>
<p>    &#60;/div&#62;</p>
<p>  &#60;/div&#62;</p>
<p>  &#60;div class="row align-items-md-stretch"&#62;</p>
<p>    &#60;div class="col-md-6"&#62;</p>
<p>      &#60;div class="h-100 p-5 text-white bg-dark rounded-3"&#62;</p>
<p>        &#60;h2&#62;Sell and Buy Faster&#60;/h2&#62;</p>
<p>        &#60;p&#62;Find people nearby, connect,</p> 
<p>           strike a deal, get paid, done.&#60;/p&#62;</p>
<p>      &#60;/div&#62;</p>
<p>  &#60;/div&#62;</p>
<p>  &#60;div class="col-md-6"&#62;</p>
<p>    &#60;div class="h-100 p-5 text-white bg-dark border rounded-3"&#62;</p>
<p>      &#60;h2&#62;Unlimited Listings&#60;/h2&#62;</p>
<p>      &#60;p&#62;Create an account and start listing immediately.</p>
<p>            No limits! No fees!&#60;/p&#62;</p>
<p>    &#60;/div&#62;</p>
<p>  &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>  &#60;footer class="pt-3 mt-4 text-muted border-top"&#62;</p>
<p>    © BikeFinder 2021</p>
<p>  &#60;/footer&#62;</p>
<p>  &#60;/div&#62;</p>
<p>&#60;/main&#62;</p>
<p>&#123;%endblock content%&#125;</p>
          </pre>

<p>Save changes and issue the
<code className="code-text"> python manage.py runserver </code>

command to see the new homepage.</p>


<div class="text-center image-div" >
    <img className="image-post" src={"https://bucketcito.s3.amazonaws.com/img/image34.png"} alt='hj'  />
    </div>

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter15">⬅️ Chapter 15</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter17">Chapter 17 ➡️ </a></p>
</div>

    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter16;

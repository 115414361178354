import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter12 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter11">⬅️ Chapter 11</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter13">Chapter 13 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 12: Register Page</h1>
            </div>
        </div>
      </div>
      <p>Let's provide a way for users to register and create accounts.</p>

<h3 class="mb-3"><strong>Register URL</strong></h3>

              <p>Open<code className="code-text"> urls.py </code> and add the path to the register page.
              </p>


              <pre className="code-box">
<p>#users/urls.py</p>
<p>from django.urls import path, include</p>
<p>from .import views</p>
<p> </p>
<p>app_name = 'users'</p>
<p>urlpatterns = [</p>
             <p>   path(' ', include('django.contrib.auth.urls')),</p>
             <p>   path('log_out/', views.log_out, name='log_out'),</p>
             <p>   path('register/', views.register, name='register'),</p>
             <p>]</p>


</pre>



<h3 class="mb-3"><strong>Register Form</strong></h3>

              <p>
To start registering users, we will create a form so that people can sign up. To avoid confusion, the <code className="code-text">forms.py</code> file to be edited is the one in the users folder which was created in chapter 11.
              </p>
              <p>
              Open <code className="code-text">forms.py</code> and add the following code.
              </p>

<pre className="code-box">
<p>#users/forms.py</p>
<p> </p>
<p>from django.contrib.auth.forms import UserCreationForm</p>
<p>from django.contrib.auth.models import User</p>
<p> </p>
<p>class UserForm(UserCreationForm)</p>
        <p>   class Meta:</p>
             <p>      model = User</p>
             <p>      fields = ['username', 'email', 'password1', 'password2']</p>
</pre>


<p><code className="code-text">UserCreationForm</code> inherits from the ModelForm class and helps create new users.</p>

<p>Django authentication system already provides us with a User model, so no need to create one.</p>

<h3 class="mb-3"><strong>Register View</strong></h3>


<p>Open <code className="code-text">views.py</code> and the following code.</p>


<pre className="code-box">
<p>#users/views.py</p>
<p>from django.urls import render, redirect</p>
<p>from .forms import UserForm</p>
<p>from django.contrib.auth import logout</p>
<p> </p>
<p>def log_out(request):</p>
<p>    logout(request)</p>
<p>    return redirect('listings:index')</p>
<p> </p>
<p>def register(request):</p>
<p>    if request.method != 'POST':</p>
<p>        form = UserForm()</p>
<p>    else:</p>
<p>        form = UserForm(data=request.POST)</p>
<p>        if form.is_valid():</p>
<p>            form.save()</p>
<p>            return redirect('users:login')</p>
<p>    context = &#123;'form': form&#125;</p>
<p>    return render(request, 'registration/register.html', context)</p>

</pre>



<p>The register view is almost identical to the new listing view.</p>

<p>First, if the request <code className="code-text">!=</code> POST, we display the <code className="code-text">UserForm</code>.</p>

<p>When people click on submit, we save the form and redirect them to the login page, so that they
can log in with their credentials.</p>

<p>Finally, the form is passed to the <code className="code-text">register.html</code> template.</p>

<h5 class="mb-3"><strong>Register Template</strong></h5>

<p>Open <code className="code-text">register.html</code> and add the following code.</p>

<pre className="code-box">
<p>&#60;--users/templates/registration/register.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p> </p>
<p>&#123;% block content %&#125;</p>
<p>&#60;h1&#62;Create Account&#60;/h1&#62;</p>
<p>&#60;form method="post" action="&#123;% url 'users:register' %&#125;"&#62;</p>
<p>        &#123;% csrf_token %&#125;</p>
<p>        &#123;&#123; form.as_p &#125;&#125;</p>
<p>        &#60;button name='submit' class='btn'&#62;Register&#60;/button&#62;</p>
<p>        &#60;input type='hidden' name="next" value="&#123;% url 'users':login' %&#125;"/&#62;</p>
<p>&#60;/form&#62;</p>
<p>&#123;% endblock content %&#125;</p>
</pre>


<h5 class="mb-3"><strong>Register - Navbar</strong></h5>

<p>Open <code className="code-text">base.html</code> and add the following code.</p>

<pre className="code-box">
<p>&#60;--listings/templates/listings/base.html--&#62;</p>
<p> </p>
<p>&#60;nav class='navbar'&#62;</p>
<p>        &#60;a href="&#123;% url 'listings:index' %&#125;"&#62;BIKE FINDER&#60;/a&#62;-</p>
<p>        &#60;a href="&#123;% url 'listings:all_listings' %&#125;"&#62;LISTINGS&#60;/a&#62;-</p>
<p>        &#60;a href="&#123;% url 'listings:my_listings' %&#125;"&#62;MY LISTINGS&#60;/a&#62;-</p>
<p> </p>
<p>        &#123;% if user.is_authenticated %&#125;</p>
<p>        &#123;&#123; user.username &#125;&#125;-</p>
<p>        &#60;a href="&#123;% url 'users:logout' %&#125;"&#62;LOGOUT&#60;/a&#62;</p>
<p>        &#123;% else %&#125;</p>
<p>        &#60;a href="&#123;% url 'users:login' %&#125;"&#62;LOGIN&#60;/a&#62;</p>
<p>        &#60;a href="&#123;% url 'users:register' %&#125;"&#62;REGISTER&#60;/a&#62;</p>
<p>        &#123;% endif %&#125;</p>
<p>&#60;/nav&#62;</p>

<p>&#123;% block content%&#125;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>

<p>Finally, we have added the register link to the navbar.</p>

<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>


    <p>Go to http://127.0.0.1:8000/users/register/, create some user accounts, and make sure that you can log in with the credentials.</p>

    <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image22.png"} alt='hj'  />
    </div>  

    <p>To verify that the register form is working, you can also log into the admin panel and go
 to <code className="code-text">Users</code> to see all users created so far.</p>

 <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image23.png"} alt='hj'  />
    </div>  
    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter11">⬅️ Chapter 11</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter13">Chapter 13 ➡️ </a></p>
</div>

    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter12;

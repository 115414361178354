import React, { useState } from "react";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import green from "@material-ui/core/colors/green";
import {Link} from "react-router-dom";


const Navigation = () => {
  


  return (
    <React.Fragment>

<Navbar style={{backgroundColor: "#efefef", fontFamily: "Sen, sans-serif"}} expand="lg" >
  <Container>
    <Navbar.Brand className="logo-brand" href="/">DEVBALT</Navbar.Brand>
    <Navbar.Toggle style={{backgroundColor:"white",  borderColor:"white", borderWidth:"1px"}} aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto justify-content-center">
       
      </Nav>
      {/*
      <Nav.Item>
          <Nav.Link style={{color: "black", marginRight: "10px", fontSize: "18px"}} href="/">Library</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link style={{color: "black", marginRight: "10px", fontSize: "18px"}} href="/">Courses</Nav.Link>
        </Nav.Item>
        */}
      {/*<button  className="postButton" disableElevation><a style={{textDecoration:"none", color:"black"}}href="/"><Typography>Get Started</Typography></a></button> */}
    </Navbar.Collapse>
  </Container>
</Navbar>


    </React.Fragment>
  );
}

export default Navigation;

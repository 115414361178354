import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter6 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter4">⬅️ Chapter 5</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter6">Chapter 7 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 6 : Navbar</h1>
            </div>
        </div>
      </div>
      <p>In this chapter, we will add a navbar with links to the other pages on the site, so that users can navigate easily.

              </p>
              <p>Create a file called <code className="code-text">base.html </code>in the templates folder. This file will serve as the parent template for the site.
              </p>


              <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/listings</p>
<p>│ │ ├── index.html</p>
<p>│ │ ├── all_listings.html</p>
<p>│ │ ├── base.html</p>
<p>│ │ └── new_listing.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>


          
          <p>Parent templates have common elements in them such as navbars, logos, sidebars, etc. We put
common elements in a parent template, so that we don't have rewrite the code in every template.</p>

<p>You don't have to use a parent template, but I recommend creating one to save time.</p>



<p>Open <code className="code-text">base.html</code> and add the following code.</p>

<pre className="code-box">
<p>&#60;--listings/templates/listings/base.html--&#62;</p>
<p> </p>
<p>&#60;nav class="navbar"&#62;</p>
<p>    &#60;a href="&#123;% url 'listings:index' %&#125;"&#62;BIKE FINDER&#60;/a&#62;-</p>
<p>    &#60;a href="&#123;% url 'listings:all_listings' %&#125;"&#62;LISTINGS&#60;/a&#62;-</p>
<p>    &#60;a href="&#123;% url 'listings:new_listing' %&#125;"&#62;NEW LISTING&#60;/a&#62;</p>
<p>&#60;/nav&#62;</p>
<p>      </p>

<p>&#123;%block content%&#125;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>


        <h5><strong>&#123;%block content%&#125; - &#123;%endblock content%&#125;</strong></h5>


        <p>These empty block tags will be filled in by content in other templates (child templates).</p>


        <h3 class="mb-3"><strong>Homepage & Navbar</strong></h3>
          
        <p>Open <code className="code-text">index.html</code> and add the following code.
        </p>
        <pre className="code-box">
<p>&#60;--listings/templates/listings/index.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h3&#62;Bike Find3r&#60;/h3&#62;</p>
<p>&#60;p&#62;This is a homepage&#60;/p&#62;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>
         

<h5><strong>&#123;% extends "parent_template" %&#125;</strong></h5>


<p>The extends tag lets Django know from which parent template the child template inherits from.</p>

 <p>In the code above, <code className="code-text">base.html</code> is the parent template from which the child
template <code className="code-text">index.html</code> inherits from.
            </p>

            <p>The extends tag takes from the parent template<code className="code-text">base.html</code> the navbar and an empty pair of content block tags.
            </p>
        
          <p>The same content block tags are present in the child template. These tags wrap the information that we want to display.</p>
          <p>Every page displays different information, so wrap the information in the templates with the content block tags and add the extends tag in order to have the navbar in that particular page.</p>


   <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image10.png"} alt='hj'  />
    </div>  


    <p>We will follow the same process for the other two pages.</p>


  <h3 class="mb-3"><strong>Listings Page & Navbar</strong></h3>

  <p>Open <code className="code-text">all_listings.html</code> and edit it as shown.
            </p>


            <pre className="code-box">
<p>&#60;--listings/templates/listings/all_listings.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h1&#62;LISTINGS&#60;/h1&#62;</p>
<p>&#60;div class="col"&#62;</p>
<p>   &#60;div class="card"&#62;</p>
<p>       &#123;% for listing in all_listings %&#125;</p>
<p>      &#60;p&#62;&#60;b&#62;Title:&#60;/b&#62;&#123;&#123; listings.title &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Condition:&#60;/b&#62;&#123;&#123; listings.condition &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Sale Type:&#60;/b&#62;&#123;&#123; listings.sale_type &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Price:&#60;/b&#62;&#123;&#123; listings.price|floatformat:2 &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Location:&#60;/b&#62;&#123;&#123; listings.city &#125;&#125;,&#123;&#123; listings.state &#125;&#125;&#60;/p&#62;</p>
<p>      &#60;p&#62;&#60;b&#62;Post Date:&#60;/b&#62;&#123;&#123; listings.list_date &#125;&#125;&#60;/p&#62;</p>

<p>      &#60;ul&#62;</p>
<p>        &#60;a class="btn" href=""&#62; View Listing&#60;/a&#62;</p>
<p>      &#60;/ul&#62;</p>
<p>&#60;hr&#62;</p>
<p>    &#123;% endfor %&#125;</p>
<p>   &#60;/div&#62;</p>
<p>&#60;/div&#62;</p>
<p>&#123;%endblock content%&#125;</p>
</pre>

<p> The content of the listings page didn't change at all. We just added the extends tag and wrapped
 the information of the listings page by content block tags.</p>


 <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image11.png"} alt='hj'  />
    </div>  




    <h3 class="mb-3"><strong>New Listing Page & Navbar</strong></h3>
          
          <p>Open <code className="code-text">new_listing.html</code> and add the following code.
          </p>



          <pre className="code-box">
<p>&#60;--listings/templates/listings/new_listing.html--&#62;</p>
<p>&#123;% extends "listings/base.html" %&#125;</p>
<p>&#123;%block content%&#125;</p>
<p>&#60;h1&#62;NEW LISTING&#60;/h1&#62;</p>

<p>&#60;form action="&#123;% url 'listings:new_listing' %&#125;"</p>
<p>          method='post' enctype='multipart/form-data'&#62;</p>

<p>&#60;div class="form-group"&#62;</p>
<p>     &#123;% csrf_token %&#125;</p>
<p>     &#123;&#123; form.as_p &#125;&#125;</p>
<p>&#60;/div&#62;</p>

<p>&#60;button type="submit" class="btn"&#62;Create Listing</p>
<p>&#60;/button&#62;</p>
<p>&#60;/form&#62;</p>

</pre>



<p>Check that all links work and that you can navigate easily between the pages on the site before
 moving on.</p>


 <div class="text-center image-div" >
    <img className="image-post-chapter3" src={"https://bucketcito.s3.amazonaws.com/img/image12.png"} alt='hj'  />
    </div>  

    <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter5">⬅️ Chapter 5</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter7">Chapter 7 ➡️ </a></p>
</div>



    </div>



  
   
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter6;

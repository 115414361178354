import React, { useEffect, useState } from "react";
import {  ThemeProvider, Grid, } from "@material-ui/core";
import theme from "../../theme/theme"
import Navbar from "../../components/Navbar";
import "./index.css";




const Chapter11 = () => {


  return (
<>
    
  <ThemeProvider theme={theme}>
    <Navbar/>
  

<div className="blog-container">
<div class="container" >
      <div class="row align-items-end ">
        <div class="col-lg-12 col-xl-12">
            <div class="section-title mb-60">
            <div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter10">⬅️ Chapter 10</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter12">Chapter 12 ➡️ </a></p>
</div>
              <h1 className="main-title">Chapter 11: Users App</h1>
            </div>
        </div>
      </div>
      <p>In this chapter, we will create a new app which will allow users to create an account and start creating listings.</p>

      <p>Issue the following command in the terminal window. We issued a similar command when we created the listings app.</p>


<pre className="code-box">
<p>python manage.py startapp users</p>
</pre>


<h3 class="mb-3"><strong>Delete Listing View</strong></h3>

              <p>Open<code className="code-text"> settings.py </code> from the <code className="code-text"> example </code> directory and include the users app to the <code className="code-text"> INSTALLED_APPS </code> section
              </p>


              <pre className="code-box">
<p>#example/settings.py</p>
<p>...</p>
<p>INSTALLED_APPS = [</p>
<p>'django.contrib.admin',</p>
<p>'django.contrib.auth',</p>
<p>'django.contrib.contenttypes',</p>
<p>'django.contrib.sessions',</p>
<p>'django.contrib.messages',</p>
<p>'django.contrib.staticfiles',</p>
<p>'listings',</p>
<p>'users',</p>
<p>]</p>
<p>...</p>
</pre>
         

<p>After issuing the <code className="code-text"> python manage.py startapp </code>
command, a new directory called
<code className="code-text"> users </code> with a new set
 </p>


              <pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>├── media</p>
<p>├── users</p>
<p>│ ├── migrations</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>


<p>Create the following folders and files in the users folder.</p>

<p>Create two Python files, <code className="code-text">urls.py</code> and <code className="code-text">forms.py</code></p>
          
<p>Create a new folder called <code className="code-text">templates</code></p>

<p>Inside the templates folder create a folder named<code className="code-text"> registration</code></p>

<p>Inside that registration folder, create two HTML files,<code className="code-text">register.html</code> and <code className="code-text"> login.html</code></p>

<p>Notice there is a folder called <code className="code-text">media</code>. This folder is created automatically when uploading image files through the new listing form. We set the path to the media folder back in chapter 1.</p>

<p>See updated project folder tree.</p>


<pre className="code-box">
                <p>PROJECT</p>
<p>└── example</p>
<p>├── listings</p>
<p>├── media</p>
<p>├── users</p>
<p>│ ├── migrations</p>
<p>│ ├── templates/registration</p>
<p>│ │ ├── login.html</p>
<p>│ │ └── register.html</p>
<p>│ ├── __init__.py</p>
<p>│ ├── admin.py</p>
<p>│ ├── apps.py</p>
<p>│ ├── forms.py</p>
<p>│ ├── models.py</p>
<p>│ ├── tests.py</p>
<p>│ ├── urls.py</p>
<p>│ └── views.py</p>
<p>├── venv</p>
<p>└── manage.py</p>
</pre>

<p>Before moving on, include the path to the URLs of the new users app.</p>

<p>To do this, open the main <code className="code-text">urls.py</code> file and add the path.</p>


<pre className="code-box">
<p>#example/urls.py</p>

<p>from django.contrib import admin</p>
<p>from django.urls import path</p>
<p> </p>
<p>urlpatterns = [</p>
             <p>   path('admin/', admin.site.urls),</p>
             <p>   path(' ', include('listings.urls')),</p>
             <p>   path('users/', include('users.urls')),</p>
             <p>]</p>


</pre>


<p>Django offers a built-in authentication system which comes with the Django package. The authentication system is called <code className="code-text">django.contrib.auth</code>, and it offers default views such as login, logout, password_change, and password_reset.</p>

<p>We will use the login view for the website.</p>

<p>If using the Django built-in authentication system, make sure there is a folder

called <code className="code-text">registration</code> and include all HTML templates inside this folder. We created this folder earlier.</p>


<p>Django default views look for templates in the registration folder only, so if you named the folder differently, Django will not be able to find the templates.</p>


<h3 class="mb-3"><strong>Login Page</strong></h3>

<h5 class="mb-3"><strong>Login URL</strong></h5>


<p>Open <code className="code-text">urls.py</code> from the users folder and add the following path.</p>


<pre className="code-box">
<p>#users/urls.py</p>
<p>from django.urls import path, include</p>
<p> </p>
<p>app_name = 'users'</p>
<p>urlpatterns = [</p>
             <p>   path(' ', include('django.contrib.auth.urls')),</p>
             <p>]</p>


</pre>

<p>The path to the login view is a bit different than what we are used to seeing. This is how Django understands the path, <code className="code-text">http://127.0.0.1:8000/users/login</code>, so basically, we are sending people to a default login view that Django has created for us.</p>


<h5 class="mb-3"><strong>Login View</strong></h5>

<p>Django already has provided a login view for us, so no need to create one.</p>

<h5 class="mb-3"><strong>Login Template</strong></h5>

<p>Open <code className="code-text">login.html</code> and add the following code.</p>

<pre className="code-box">
<p>&#60;--users/templates/registration/login.html--&#62;</p>
<p> </p>
<p>&#60;h2&#62;LOGIN&#60;/h2&#62;</p>

<p>&#60;form method='POST'&#62; action="&#123;% url 'users':login' %&#125;"&#62;</p>
<p>        &#123;% csrf_token %&#125;</p>
<p>        &#123;&#123; form.as_p &#125;&#125;</p>
<p>         &#60;button name='submit' class='btn'&#62;Submit&#60;/button&#62;</p>
<p>         &#60;input type='hidden' name="next" value="&#123;% url 'listings':index' %&#125;"/&#62;</p>
<p>&#60;/form&#62;</p>
</pre>

<p>Users have to enter their credentials and submit the login form to access the website. After logging
in, users will be taken to the homepage.</p>

<h5 class="mb-3"><strong>Login - Navbar</strong></h5>

<p>Open <code className="code-text">base.html</code> and add the following code.</p>

<pre className="code-box">
<p>&#60;--listings/templates/listings/base.html--&#62;</p>
<p> </p>
<p>&#60;nav class='navbar'&#62;</p>
<p>         &#60;a href="&#123;% url 'listings:index' %&#125;"&#62;BIKE FINDER&#60;/a&#62;-</p>
<p>         &#60;a href="&#123;% url 'listings:all_listings' %&#125;"&#62;LISTINGS&#60;/a&#62;-</p>
<p>         &#60;a href="&#123;% url 'listings:my_listings' %&#125;"&#62;MY LISTINGS&#60;/a&#62;-</p>
<p> </p>
<p>        &#123;% if user.is_authenticated %&#125;</p>
<p>        &#123;&#123; user.username &#125;&#125;-</p>
<p>        &#60;a href="&#123;% url 'users:logout' %&#125;"&#62;LOGOUT&#60;/a&#62;</p>
<p>        &#123;% else %&#125;</p>
<p>        &#60;a href="&#123;% url 'users:login' %&#125;"&#62;LOGIN&#60;/a&#62;</p>
<p>        &#123;% endif %&#125;</p>
<p>&#60;/nav&#62;</p>

<p>&#123;% block content%&#125;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>

<p>Save changes and issue the <code className="code-text">python manage.py runserver</code> command.</p>

<p>If a user is not logged in, the navbar will display the login link.</p>

<div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image18.png"} alt='hj'  />
    </div>  

    <p>Go to http://127.0.0.1:8000/users/login/ and try logging in with the admin credentials.</p>

    <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image19.png"} alt='hj'  />
    </div>  

    <p><code className="code-text">is_authenticated</code> helps check if a user is logged in, if that's the case, the navbar will show the
 username and the logout link.</p>

 <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image20.png"} alt='hj'  />
    </div>  


  <p>When users log out, they’re taken to the admin logout page that Django provides.</p>

  <div class="text-center image-div" >
    <img className="image-post-chapter2" src={"https://bucketcito.s3.amazonaws.com/img/image21.png"} alt='hj'  />
    </div>  

<p>We will redirect users to homepage rather than showing them the admin logout page.</p>

<h5 class="mb-3"><strong>Login URL</strong></h5>
<p>Open <code className="code-text">urls.py</code> and add the following path.</p>


<pre className="code-box">
<p>#users/urls.py</p>
<p>from django.urls import path, include</p>
<p>from .import views</p>
<p> </p>
<p>app_name = 'users'</p>
<p>urlpatterns = [</p>
             <p>   path(' ', include('django.contrib.auth.urls')),</p>
             <p>   path('log_out/', views.log_out, name='log_out'),</p>
             <p>]</p>


</pre>

<p>We use <code className="code-text">log_out</code> for the URL, view, and template because there is already a default view that Django provides which is called <code className="code-text">logout</code>, so to avoid conflicts we chose a different name.</p>


<h5 class="mb-3"><strong>Login View</strong></h5>
<p>Open <code className="code-text">views.py</code> from the users folder and add the following code.</p>

<pre className="code-box">
<p>#users/views.py</p>
<p>from django.shortcuts import render, redirect</p>
<p>from django.contrib.auth import logout</p>
<p> </p>
<p>def log_out(request):</p>
<p>    logout(request)</p>
<p>    return redirect('listings:index')</p>


</pre>

<p>From <code className="code-text">django.contrib.auth</code>, import the <code className="code-text">logout</code> function which logs users out. After doing this, we’ll redirect users to the homepage.</p>

<p>There's no need for a logout template, but you could add one if you want and redirect users to that page instead of the homepage.</p>

<h5 class="mb-3"><strong>Logout - Navbar</strong></h5>
<p>Open <code className="code-text">base.html</code> and change the default <code className="code-text">logout</code> view for the new <code className="code-text">log_out</code> view.</p>

<pre className="code-box">
<p>&#60;--listings/templates/listings/base.html--&#62;</p>
<p> </p>
<p>&#60;nav class='navbar'&#62;</p>
<p>         &#60;a href="&#123;% url 'listings:index' %&#125;"&#62;BIKE FINDER&#60;/a&#62;-</p>
<p>         &#60;a href="&#123;% url 'listings:all_listings' %&#125;"&#62;LISTINGS&#60;/a&#62;-</p>
<p>         &#60;a href="&#123;% url 'listings:my_listings' %&#125;"&#62;MY LISTINGS&#60;/a&#62;-</p>
<p> </p>
<p>        &#123;% if user.is_authenticated %&#125;</p>
<p>        &#123;&#123; user.username &#125;&#125;-</p>
<p>        &#60;a href="&#123;% url 'users:log_out' %&#125;"&#62;LOGOUT&#60;/a&#62;</p>
<p>        &#123;% else %&#125;</p>
<p>        &#60;a href="&#123;% url 'users:login' %&#125;"&#62;LOGIN&#60;/a&#62;</p>
<p>        &#123;% endif %&#125;</p>
<p>&#60;/nav&#62;</p>

<p>&#123;% block content%&#125;</p>
<p>&#123;% endblock content%&#125;</p>
</pre>


<p>Save changes and run the server again.</p>

<p>When you click on the logout link, it should redirect you to the homepage instead of the admin logout page.</p>


<div class="footer">
  <p class="left-link"><a class="footer-links"href="/takeoff/chapter10">⬅️ Chapter 10</a></p>
  <p class="right-link"><a class="footer-links" href="/takeoff/chapter12">Chapter 12 ➡️ </a></p>
</div>
    </div>
</div>

  </ThemeProvider>
  </>
  );
};

export default Chapter11;
